import { ErrorText } from 'components'
import React, { ReactElement } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { MaskCEP, maskCpfCnpj, MaskPhone } from 'services/utils'

function InputMasked(
  props: UseControllerProps & NumberFormatProps
): ReactElement {
  const {
    field: { onChange, ...rest },
    fieldState: { error }
  } = useController(props)
  return (
    <>
      <NumberFormat
        {...props}
        className="ant-input ant-input-lg mc-input"
        onValueChange={val => {
          onChange(val.value)
        }}
        {...rest}
      />
      {error && <ErrorText>{error.message}</ErrorText>}
    </>
  )
}

InputMasked.CPFCNPJ = function CPFCNPJ(
  props: UseControllerProps & NumberFormatProps
) {
  const {
    field: { onChange, ...rest },
    fieldState: { error }
  } = useController({
    ...props,
    rules: { required: 'O CNPJ precisa ser informado' }
  })
  return (
    <>
      <NumberFormat
        {...props}
        format={maskCpfCnpj}
        placeholder="CNPJ/CPF"
        className="ant-input ant-input-lg mc-input"
        onValueChange={val => {
          onChange(val.value)
        }}
        {...rest}
      />
      {error && <ErrorText>{error.message}</ErrorText>}
    </>
  )
}

InputMasked.Telefone = function Telefone(
  props: UseControllerProps & Omit<NumberFormatProps, keyof UseControllerProps>
) {
  const {
    field: { onChange, ...rest },
    fieldState: { error }
  } = useController({
    ...props,
    rules: { required: 'O telefone precisa ser informado' }
  })
  return (
    <>
      <NumberFormat
        {...props}
        format={MaskPhone}
        placeholder="Telefone"
        className="ant-input ant-input-lg mc-input"
        onValueChange={val => {
          onChange(val.value)
        }}
        {...rest}
      />
      {error && <ErrorText>{error.message}</ErrorText>}
    </>
  )
}

InputMasked.Money = function Area(
  props: UseControllerProps & Omit<NumberFormatProps, keyof UseControllerProps>
) {
  const {
    field: { onChange, ...rest },
    fieldState: { error }
  } = useController(props)
  return (
    <>
      <NumberFormat
        {...props}
        className="ant-input ant-input-lg mc-input"
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        prefix={'R$ '}
        onValueChange={val => {
          onChange(val.value)
        }}
        {...rest}
      />
      {error && <ErrorText>{error.message}</ErrorText>}
    </>
  )
}

InputMasked.CEP = function CEP(
  props: UseControllerProps & Omit<NumberFormatProps, keyof UseControllerProps>
) {
  const {
    field: { onChange, ...rest },
    fieldState: { error }
  } = useController(props)
  return (
    <>
      <NumberFormat
        {...props}
        format={MaskCEP}
        placeholder="CEP"
        className="ant-input ant-input-lg mc-input"
        onValueChange={val => {
          onChange(val.value)
        }}
        {...rest}
      />
      {error && <ErrorText>{error.message}</ErrorText>}
    </>
  )
}
export default InputMasked
