import React, { useEffect, useState } from 'react'
import { Button, Col, Input, message, Row, Spin, Typography } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import api from 'services/api'
import useRouter from 'Hooks/useRoutes'
import { useParams } from 'react-router-dom'

const { Text } = Typography

const Recuperar: React.FC = () => {
  const { navigate } = useRouter()
  const { token } = useParams() as { token: string }
  const [loading, setLoading] = useState(false)
  const { control, watch, handleSubmit, reset } = useForm({ mode: 'all' })
  const password = watch('password')

  const onSubmit = async (data: any) => {
    setLoading(true)
    try {
      await api.post('/redefinir', data).then(() => {
        localStorage.clear()
        reset({})
        message.success('Senha alterada com sucesso!')
        navigate('/')
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    localStorage.setItem('TOKEN_API', token)
  }, [token])

  return (
    <Row className="container" justify="center" align="middle">
      <Col xxl={5} xl={6} lg={8} md={10} sm={18} xs={20}>
        <Spin spinning={loading}>
          <Col className="loginForm">
            <Col className="marginTopBottom">
              <Controller
                name="password"
                control={control}
                rules={{
                  required: 'A senha precisa ser informada'
                }}
                render={({
                  field: { value, onBlur, onChange },
                  fieldState: { error }
                }) => (
                  <>
                    <Input.Password
                      size="large"
                      placeholder="Senha"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                    />
                    {error && <Text type="danger">{error.message}</Text>}
                  </>
                )}
              />
            </Col>
            <Col className="marginTopBottom">
              <Controller
                name="confirm_password"
                control={control}
                rules={{
                  required: 'A senha deve ser informada',
                  validate: value =>
                    value === password || 'As senhas não são iguais'
                }}
                render={({
                  field: { value, onBlur, onChange },
                  fieldState: { error }
                }) => (
                  <>
                    <Input.Password
                      size="large"
                      placeholder="Confirme a senha"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                    />
                    {error && <Text type="danger">{error.message}</Text>}
                  </>
                )}
              />
            </Col>
            <Row className="marginTopBottom">
              <Button
                size="large"
                type="primary"
                block
                onClick={() => handleSubmit(onSubmit)()}
              >
                ENVIAR
              </Button>
              <Button
                size="large"
                type="text"
                block
                onClick={() => {
                  localStorage.clear()
                  navigate('/')
                }}
              >
                Ir para login?
              </Button>
            </Row>
          </Col>
        </Spin>
      </Col>
    </Row>
  )
}

export default Recuperar
