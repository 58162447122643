import React, { useState } from 'react'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Col,
  Row,
  Typography,
  Button,
  Table,
  message,
  Space,
  Tooltip,
  Popconfirm
} from 'antd'
import { Card, Container, IconFont, InputSearch, Title } from 'components'
import { IProduto, status } from 'types'
import api from 'services/api'
import { formatarMoeda } from 'services/utils'
import Produto from 'components/Drawer/Produto'
import { useQueryClient } from 'react-query'
import { useProdutos } from 'Hooks/functions'

const { Column } = Table
interface drawer {
  drawer: status
  produto?: IProduto
}

const Produtos: React.FC = () => {
  const [drawer, setDrawer] = useState<drawer>({
    drawer: 'none'
  })

  const queryClient = useQueryClient()
  const { data: produtos, isFetching: loading } = useProdutos()

  const onDelete = async (id: number) => {
    await api.delete(`/produto/${id}`).then(() => {
      message.success('Produto excluido com sucesso!')
      queryClient.invalidateQueries('produtos')
    })
  }

  return (
    <Container>
      <Col span={24} style={{ padding: 10 }}>
        <Row justify="start" align="middle" style={{ margin: '10px 0' }}>
          <IconFont
            type="icon-sticker"
            style={{ fontSize: 24, marginRight: 10 }}
          />
          <Title>Produto</Title>
        </Row>
        <Row style={{ margin: '10px 0' }}>
          <Typography.Text type="secondary">
            {produtos ? produtos.length : 0} registros
          </Typography.Text>
        </Row>
        <Row wrap={false} gutter={8} style={{ margin: '10px 0' }}>
          <Col span={16}>
            <InputSearch size="large" placeholder="Pesquisar" />
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              shape="round"
              size="large"
              icon={<PlusOutlined />}
              onClick={() =>
                setDrawer({
                  drawer: 'insert'
                })
              }
            >
              NOVO
            </Button>
          </Col>
        </Row>
        <Card property="nopadding" style={{ margin: '10px 0' }}>
          <Table
            dataSource={produtos}
            loading={loading}
            rowKey={record => record.id}
          >
            <Column
              title="CÓD"
              dataIndex="id"
              key="id"
              responsive={['xxl', 'xl', 'lg']}
            />
            <Column title="PRODUTO" dataIndex="nome" key="nome" />
            <Column
              title="DESCRIÇÃO"
              dataIndex="descricao"
              key="descricao"
              responsive={['xxl', 'xl', 'lg', 'md']}
            />
            <Column
              title="UNIDADE"
              dataIndex="unidade"
              key="unidade"
              render={(_, record: IProduto) => record.unidade.unidade}
              responsive={['xxl', 'xl', 'lg', 'md']}
            />
            <Column
              title="VALOR"
              dataIndex="valor"
              key="valor"
              render={valor => formatarMoeda(valor)}
            />
            <Column
              key="actions"
              render={(_, record: IProduto) => (
                <Space>
                  <Tooltip title="Editar">
                    <Button
                      icon={<EditOutlined />}
                      type="text"
                      shape="circle"
                      onClick={() => {
                        setDrawer({
                          drawer: 'update',
                          produto: record
                        })
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Apagar" placement="top">
                    <Popconfirm
                      title="Deseja realmente apagar este registro?"
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={() => onDelete(record.id)}
                    >
                      <Button
                        shape="circle"
                        type="text"
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </Tooltip>
                </Space>
              )}
            />
          </Table>
        </Card>
      </Col>
      <Produto setVisible={setDrawer} config={drawer} />
    </Container>
  )
}

export default Produtos
