import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import {
  PlusOutlined,
  DeleteOutlined,
  SyncOutlined,
  CheckSquareOutlined
} from '@ant-design/icons'
import {
  Drawer,
  Row,
  Spin,
  Col,
  Button,
  Table,
  Typography,
  message
} from 'antd'
import { IconFont, Title, Label, Descricao, Card } from 'components'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import api from 'services/api'
import { formatarMoeda } from 'services/utils'
import { IPedidoPagamento, IPedido, ICaixa } from 'types'
import Select from 'components/Controlled/Select'
import InputMasked from 'components/Controlled/InputMasked'
import Input from 'components/Controlled/Input'
import { useQueryClient } from 'react-query'

const { Column } = Table

type status = 'insert' | 'update' | 'service' | 'payment' | 'none'

interface drawer {
  drawer: status
  pedido?: IPedido
  caixa: ICaixa | undefined
}

interface Props {
  config: drawer
  setVisible: Dispatch<SetStateAction<drawer>>
}

export default function Pagamento({
  config: { drawer, caixa, pedido },
  setVisible
}: Props): ReactElement {
  const { reset, control, watch, trigger, clearErrors, setValue } = useForm({
    mode: 'onBlur'
  })
  const queryClient = useQueryClient()
  const [total, setTotal] = useState(0)
  const [recebido, setRecebido] = useState(0)
  const [waiting, setWaiting] = useState(false)
  const formaPagamento = watch('formaPagamento')
  const parcelas = watch('parcelas')
  const valor = watch('valor')
  const pagamento = watch('pagamento')
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pagamento'
  })

  useEffect(() => {
    if (pedido) {
      let valor = 0
      pedido.produtos.forEach(produto => {
        valor +=
          produto.quantidade * Number(produto.produto.valor) - produto.desconto
      })
      setTotal(valor)
    }
  }, [pedido])

  const onClose = () => {
    reset({
      pagamento: []
    })
    setRecebido(0)
    setVisible({ drawer: 'update', caixa, pedido })
  }

  const onSave = async () => {
    if (pedido) {
      clearErrors()
      setWaiting(true)
      await api
        .put(`/finalizar_pedido/${pedido.id}`, {
          valor: total,
          status: 'Finalizado',
          pagamento
        })
        .then(() => {
          setWaiting(false)
          message.success('Pedido finalizado com sucesso!')
          reset({
            pagamento: []
          })
          setRecebido(0)
          setTotal(0)
          queryClient.invalidateQueries('pedidos')
          setVisible({ drawer: 'none', caixa })
        })
        .catch(() => setWaiting(false))
    }
  }

  return (
    <Drawer visible={drawer === 'payment'} onClose={onClose} width={430}>
      <Row justify="start" align="middle" style={{ margin: 10 }}>
        <IconFont
          type="icon-wallet"
          style={{ fontSize: 24, marginRight: 15 }}
        />
        <Title>Finalizar Pedido.</Title>
      </Row>
      <Spin spinning={waiting}>
        <Col span={24} style={{ margin: '10px 0' }}>
          <Label>FORMA DE PAGAMENTO</Label>
          <Select
            control={control}
            rules={{ required: 'A forma de pagamento precisa ser informada' }}
            name="formaPagamento"
            placeholder="Selecione..."
          >
            <Select.Option value="dinheiro" key="dinheiro">
              Dinheiro
            </Select.Option>
            <Select.Option value="credito" key="credito">
              Cartão de crédito
            </Select.Option>
            <Select.Option value="debito" key="debito">
              Cartão de débito
            </Select.Option>
            <Select.Option value="pix" key="pix">
              Pix
            </Select.Option>
          </Select>
        </Col>
        <Row wrap={false} gutter={8} style={{ margin: '10px 0' }}>
          <Col span={18}>
            <Label>VALOR RECEBIDO</Label>
            <InputMasked.Money
              name="valor"
              control={control}
              rules={{
                required: 'O valor precisa ser informado',
                min: { value: 1, message: 'O valor precisa ser informado' }
              }}
              allowNegative={false}
            />
          </Col>
          <Col span={6}>
            <Label>PARCELAS</Label>
            <Input
              name="parcelas"
              control={control}
              defaultValue={1}
              type="number"
              disabled={
                formaPagamento !== 'credito' && formaPagamento !== 'prazo'
              }
            />
          </Col>
        </Row>
        <Row wrap={false} gutter={8} style={{ margin: '10px 0' }}>
          <Col span={12}>
            <Descricao>VALOR A PAGAR</Descricao>
            <Label color="#EB665A">
              {total - recebido > 0
                ? formatarMoeda(total - recebido)
                : formatarMoeda(0)}
            </Label>
          </Col>
          <Col span={12}>
            <Button
              icon={<PlusOutlined />}
              shape="round"
              type="primary"
              block
              size="large"
              onClick={async () => {
                const validated = await trigger([
                  'formaPagamento',
                  'parcelas',
                  'valor'
                ])
                if (validated) {
                  setRecebido(recebido + Number(valor))
                  append({
                    formaPagamento,
                    valor,
                    parcelas
                  })
                  setValue('formaPagamento', undefined)
                  setValue('valor', 0)
                  setValue('parcelas', 1)
                  clearErrors()
                }
                clearErrors()
              }}
            >
              ADICIONAR
            </Button>
          </Col>
        </Row>
        <Card property="nopadding">
          <Table
            dataSource={fields}
            rowKey={record => record.id}
            pagination={{ position: [] }}
          >
            <Column
              title="PAGAMENTO"
              key="formaPagamento"
              render={(_, record: IPedidoPagamento, index) => (
                <Controller
                  control={control}
                  name={`pagamento[${index}].formaPagamento`}
                  defaultValue={record.formaPagamento}
                  render={({ field: { value } }) => (
                    <Typography.Text>{value}</Typography.Text>
                  )}
                />
              )}
            />
            <Column
              title="VALOR"
              key="valor"
              render={(_, record: IPedidoPagamento, index) => (
                <Controller
                  control={control}
                  name={`pagamento[${index}].valor`}
                  defaultValue={record.valor}
                  render={({ field: { value } }) => (
                    <Typography.Text>{formatarMoeda(value)}</Typography.Text>
                  )}
                />
              )}
            />
            <Column
              title="PARCELAS"
              key="parcelas"
              render={(_, record: IPedidoPagamento, index) => (
                <Controller
                  control={control}
                  name={`pagamento[${index}].parcelas`}
                  defaultValue={record.parcelas}
                  render={({ field: { value } }) => (
                    <Typography.Text>{value}</Typography.Text>
                  )}
                />
              )}
            />
            <Column
              key="actions"
              render={(_, record: IPedidoPagamento, index) => (
                <Button
                  shape="circle"
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setRecebido(recebido - record.valor)
                    remove(index)
                  }}
                />
              )}
            />
          </Table>
        </Card>
        <Row
          style={{
            background: 'rgba(253, 197, 0, 0.15)',
            margin: '10px 0',
            border: 'none',
            borderRadius: 20
          }}
        >
          <Col span={12}>
            <Row align="middle" justify="center">
              <SyncOutlined
                style={{ fontSize: 30, color: '#FDC500', marginRight: 5 }}
              />
              <Col style={{ margin: 5 }}>
                <Label>
                  {recebido - total > 0
                    ? formatarMoeda(recebido - total)
                    : formatarMoeda(0)}
                </Label>
                <Descricao color="#888888">Troco</Descricao>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row align="middle" justify="center">
              <CheckSquareOutlined
                style={{ fontSize: 30, color: '#FDC500', marginRight: 5 }}
              />
              <Col style={{ margin: 5 }}>
                <Label>{formatarMoeda(recebido)}</Label>
                <Descricao color="#888888">Recebido</Descricao>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" gutter={8} style={{ margin: '10px 0' }}>
          <Col span={12}>
            <Button shape="round" size="large" block onClick={onClose}>
              CANCELAR
            </Button>
          </Col>
          <Col span={12}>
            <Button
              shape="round"
              type="primary"
              block
              size="large"
              onClick={onSave}
            >
              FINALIZAR
            </Button>
          </Col>
        </Row>
      </Spin>
    </Drawer>
  )
}
