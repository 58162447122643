import { createFromIconfontCN } from '@ant-design/icons'
import { Divider, Input, Row, Select, Button } from 'antd'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'

export const Title = styled.h2`
  margin: 0;
  font-weight: bold;
  color: #0f3d59;
`
export const TitleSecondary = styled.h3`
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  color: ${props => (props.color ? props.color : '#0F3D59')};
`

export const Label = styled.h4`
  margin: 0;
  font-weight: bold;
  color: ${props => (props.color ? props.color : '#0F3D59')};
`
export const Descricao = styled.h5`
  margin: 0;
  font-weight: bold;
  color: ${props => (props.color ? '#888888' : '#0F3D59')};
`

export const ErrorText = styled.p`
  margin: 0;
  font-weight: 500;
  color: #eb2626;
`

export const Container = styled(Row)`
  display: flex;
  height: 100%;
  flex: 1;
`
export const Card = styled.div`
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 20px;
  overflow: hidden;
  padding: ${props => (props.property === 'nopadding' ? 0 : '5px')};
`

export const InputText = styled(Input).attrs(() => ({
  size: 'large'
}))`
  background-color: #eff0f6;
  border-radius: 7px;
  input {
    background-color: #eff0f6;
  }
`
export const InputSearch = styled(Input.Search)`
  background-color: #eff0f6;
  border-radius: 10px;
  input,
  button,
  span {
    background-color: #eff0f6;
  }
`

export const InputPassword = styled(Input.Password).attrs(() => ({
  size: 'large'
}))`
  background-color: #eff0f6;
  border-radius: 7px;
  input {
    background-color: #eff0f6;
  }
`
export const InputArea = styled(Input.TextArea).attrs(() => ({
  size: 'large'
}))`
  background-color: #eff0f6;
  border-radius: 7px;
  input {
    background-color: #eff0f6;
  }
`
export const InputMoney = styled(NumberFormat).attrs(() => ({
  className: 'ant-input ant-input-lg',
  thousandSeparator: '.',
  decimalSeparator: ',',
  prefix: 'R$ ',
  decimalScale: 2,
  fixedDecimalScale: true
}))`
  background-color: #eff0f6;
  border-radius: 10px;
`
export const InputMask = styled(NumberFormat).attrs(() => ({
  className: 'ant-input ant-input-lg'
}))`
  background-color: #eff0f6;
  border-radius: 10px;
`

export const Selector = styled(Select).attrs(() => ({
  size: 'large'
}))`
  width: 100%;
  overflow: hidden;
  input {
    background-color: #eff0f6;
  }
`
export const RoundButton = styled(Button).attrs(() => ({
  size: 'large'
}))`
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
`

export const Divisoria = styled(Divider)`
  margin: 8px 0;
`
export const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2603953_7t0cmrl2flt.js'
})
