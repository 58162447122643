import React, { useEffect, useState } from 'react'
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
  ToolOutlined
} from '@ant-design/icons'
import {
  Col,
  Row,
  Typography,
  Button,
  Table,
  Drawer,
  message,
  Space,
  Tooltip,
  Popconfirm,
  Spin,
  Tag,
  Switch,
  Dropdown
} from 'antd'
import { Card, Container, IconFont, InputText, Label, Title } from 'components'
import { IEmpresa } from 'types'
import api from 'services/api'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { maskCpfCnpj, MaskPhone } from 'services/utils'
import { useAuth } from 'Context/AuthContext'
import { useEmpresas } from 'Hooks/functions'
import { useQueryClient } from 'react-query'
import Input from 'components/Controlled/Input'
import InputMasked from 'components/Controlled/InputMasked'
import Select from 'components/Controlled/Select'

const { Column } = Table
type status = 'insert' | 'update' | 'none'

const Empresas: React.FC = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const [drawer, setDrawer] = useState<status>('none')
  const [waiting, setWaiting] = useState(false)
  const [empresa, setEmpresa] = useState(0)
  const { data: empresas, isFetching: loading } = useEmpresas()
  const [filter, setFilter] = useState(empresas)
  const { handleSubmit, control, reset } = useForm({ mode: 'all' })

  const onSubmit: SubmitHandler<any> = async data => {
    setWaiting(true)
    try {
      drawer === 'update'
        ? await api.put(`/empresa/${empresa}`, data).then(() => {
            setWaiting(false)
            message.success('Empresa atualizada com sucesso!')
            onClose()
            queryClient.invalidateQueries('empresas')
          })
        : await api.post('/empresa', data).then(() => {
            setWaiting(false)
            message.success('Empresa cadastrada com sucesso!')
            onClose()
            queryClient.invalidateQueries('empresas')
          })
    } catch (error) {
      setWaiting(false)
    }
  }

  useEffect(() => {
    if (empresas) {
      setFilter(empresas)
    }
  }, [empresas])

  const onClose = () => {
    reset({})
    setDrawer('none')
  }

  return (
    <Container>
      <Col span={24} style={{ padding: 10 }}>
        <Row justify="start" align="middle" style={{ margin: '10px 0' }}>
          <IconFont
            type="icon-Vector"
            style={{ fontSize: 24, marginRight: 10 }}
          />
          <Title>Empresas</Title>
        </Row>
        <Row style={{ margin: '10px 0' }}>
          <Typography.Text type="secondary">
            {empresas?.length} registros
          </Typography.Text>
        </Row>
        <Row wrap={false} gutter={8} style={{ margin: '10px 0' }}>
          <Col span={16}>
            <InputText
              size="large"
              placeholder="Pesquisar"
              onChange={e => {
                if (empresas) {
                  setFilter(
                    empresas.filter(empresa =>
                      empresa.nomeFantasia
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  )
                }
              }}
              suffix={<SearchOutlined />}
            />
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              shape="round"
              size="large"
              icon={<PlusOutlined />}
              onClick={() => setDrawer('insert')}
            >
              NOVO
            </Button>
          </Col>
        </Row>
        <Card property="nopadding" style={{ margin: '10px 0' }}>
          <Table<IEmpresa>
            dataSource={filter}
            loading={loading}
            rowKey={record => record.id}
          >
            <Column<IEmpresa>
              title="NOME DA EMPRESA"
              dataIndex="nomeFantasia"
              key="nomeFantasia"
            />
            <Column<IEmpresa> title="PLANO" dataIndex="plano" key="plano" />
            <Column<IEmpresa>
              title="CNPJ/CPF"
              dataIndex="cnpjCpf"
              key="cnpjCpf"
              render={text => maskCpfCnpj(text)}
              responsive={['xxl', 'xl', 'lg']}
            />
            <Column<IEmpresa>
              title="TELEFONE"
              dataIndex="telefone"
              key="telefone"
              render={text => MaskPhone(text)}
              responsive={['xxl', 'xl', 'lg']}
            />
            <Column<IEmpresa>
              title="EMAIL"
              dataIndex="email"
              key="email"
              responsive={['xxl', 'xl', 'lg', 'md']}
            />
            <Column<IEmpresa>
              title="STATUS"
              dataIndex="status"
              key="status"
              render={status =>
                status ? <Tag color="green">Ativo</Tag> : <Tag>Inativo</Tag>
              }
            />
            <Column<IEmpresa>
              key="actions"
              render={(_, record) => (
                <Dropdown
                  trigger={['click', 'hover']}
                  overlay={() => (
                    <Space>
                      <Tooltip title="Editar" placement="top">
                        <Button
                          shape="circle"
                          type="text"
                          icon={<EditOutlined />}
                          onClick={() => {
                            setDrawer('update')
                            setEmpresa(record.id)
                            reset(record)
                          }}
                        />
                      </Tooltip>
                      {user?.empresaId !== record.id && (
                        <Tooltip title="Apagar" placement="top">
                          <Popconfirm
                            title="Deseja realmente apagar este registro?"
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={async () => {
                              await api
                                .delete(`/empresa/${record.id}`)
                                .then(() => {
                                  queryClient.invalidateQueries('empresas')
                                  message.success(
                                    'Empresa excluida com sucesso!'
                                  )
                                })
                            }}
                          >
                            <Button
                              shape="circle"
                              type="text"
                              icon={<DeleteOutlined />}
                            />
                          </Popconfirm>
                        </Tooltip>
                      )}
                    </Space>
                  )}
                >
                  <Button shape="circle" type="text" icon={<MoreOutlined />} />
                </Dropdown>
              )}
            />
          </Table>
        </Card>
      </Col>

      <Drawer
        visible={drawer !== 'none'}
        onClose={onClose}
        destroyOnClose={true}
        width={400}
      >
        <Row justify="start" align="middle" style={{ margin: 10 }}>
          <ToolOutlined style={{ fontSize: 24, marginRight: 15 }} />
          <Title>{drawer === 'insert' ? 'Nova' : 'Editar'} Empresa</Title>
        </Row>
        <Spin spinning={waiting}>
          <Col span={24} style={{ margin: '10px 0' }}>
            <Label>NOME FANTASIA</Label>
            <Input
              name="nomeFantasia"
              control={control}
              rules={{
                required: 'O nome precisa ser informado'
              }}
            />
          </Col>
          <Col span={24} style={{ margin: '10px 0' }}>
            <Label>RAZÃO SOCIAL</Label>
            <Input
              name="razaoSocial"
              control={control}
              rules={{
                required: 'A Razão Social precisa ser informada'
              }}
            />
          </Col>
          <Col span={24} style={{ margin: '10px 0' }}>
            <Label>CNPJ/CPF</Label>
            <InputMasked.CPFCNPJ
              name="cnpjCpf"
              control={control}
              rules={{
                required: 'O CNPJ precisa ser informado'
              }}
            />
          </Col>
          <Col span={24} style={{ margin: '10px 0' }}>
            <Label>TELEFONE</Label>
            <InputMasked.Telefone
              name="telefone"
              control={control}
              rules={{
                required: 'O telefone precisa ser informado'
              }}
            />
          </Col>
          <Col span={24} style={{ margin: '10px 0' }}>
            <Label>EMAIL</Label>
            <Input.Email
              name="email"
              control={control}
              rules={{ required: 'O email precisa ser informado' }}
            />
          </Col>
          <Col span={24} style={{ margin: '10px 0' }}>
            <Label>ENDEREÇO</Label>
            <Input name="endereco" control={control} />
          </Col>
          <Row gutter={12} align="middle">
            <Col span={18}>
              <Label>PLANO</Label>
              <Select
                name="plano"
                control={control}
                rules={{
                  required: 'O plano precisa ser informado'
                }}
              >
                <Select.Option key={'admin'} value={'Admin'}>
                  Administrador
                </Select.Option>
                <Select.Option key={'basico'} value={'Basico'}>
                  Plano Básico
                </Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Label>STATUS</Label>
              <Controller
                name="status"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    defaultChecked={true}
                    checkedChildren="Ativo"
                    unCheckedChildren="Inativo"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </Col>
          </Row>

          <Row justify="space-between" gutter={8} style={{ margin: '10px 0' }}>
            <Col span={12}>
              <Button shape="round" size="large" block onClick={onClose}>
                CANCELAR
              </Button>
            </Col>
            <Col span={12}>
              <Button
                shape="round"
                type="primary"
                block
                size="large"
                onClick={() => handleSubmit(onSubmit)()}
              >
                {drawer === 'insert' ? 'CRIAR' : 'SALVAR'}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Drawer>
    </Container>
  )
}

export default Empresas
