import React, { createContext, useContext, useEffect, useState } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import { ILogin, IUsuario } from '../types'
import api, { WithAxios } from '../services/api'

const TOKEN_API = 'TOKEN_API'
const STORAGE_USER = 'STORAGE_USER'

interface AuthContextData {
  signed: boolean
  user: IUsuario | null
  setUser: (user: IUsuario | null) => void
  login(data: ILogin): Promise<AxiosResponse>
  logout(): Promise<AxiosResponse>
  loading: boolean
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData)

interface Props {
  children: React.ReactElement
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<IUsuario | null>(null)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const storageUser = localStorage.getItem(STORAGE_USER)
    const storageToken = localStorage.getItem(TOKEN_API)

    if (storageToken && storageUser) {
      api.defaults.headers.common.Authorization = `Bearer ${storageToken}`
      api.defaults.headers.Authorization = `Bearer ${storageToken}`
      setUser(JSON.parse(storageUser))
    }
    setLoading(false)
  }, [])

  const login = async (request: ILogin) => {
    setLoading(true)
    return new Promise<AxiosResponse>((resolve, reject) => {
      api
        .post('/login', request)
        .then(response => {
          const { token, user } = response.data
          setUser(user)

          api.defaults.headers.common.Authorization = `Bearer ${token}`
          api.defaults.headers.Authorization = `Bearer ${token}`

          localStorage.setItem(STORAGE_USER, JSON.stringify(user))
          localStorage.setItem(TOKEN_API, token)
          setLoading(false)
          resolve(response)
        })
        .catch(error => {
          setLoading(false)
          const err = error as AxiosError
          reject(err)
        })
    })
  }

  async function logout() {
    return new Promise<AxiosResponse>(resolve => {
      setLoading(true)
      api.post('/logout').then(response => {
        localStorage.clear()
        setUser(null)
        setLoading(false)
        resolve(response)
      })
    })
  }

  return (
    <AuthContext.Provider
      value={{ signed: !!user, user, login, logout, loading, setUser }}
    >
      <WithAxios>{children}</WithAxios>
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)
  return context
}

export default AuthProvider
