import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import {
  SaveOutlined,
  EditOutlined,
  PieChartOutlined,
  HistoryOutlined,
  DollarOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined
} from '@ant-design/icons'
import {
  Col,
  Row,
  Spin,
  Tooltip,
  Button,
  message,
  Typography,
  Table,
  Popconfirm
} from 'antd'
import { Title, Card, Label, Divisoria } from 'components'
import { format, parseISO } from 'date-fns'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import { formatarMoeda } from 'services/utils'
import { ICaixa, IPedido, IPedidoProdutos } from 'types'
import AddAdicional from './AddAdicional'
import Pagamento from './Pagamento'
import Input from 'components/Controlled/Input'
import { useQueryClient } from 'react-query'

const { Column } = Table

type status = 'insert' | 'update' | 'service' | 'payment' | 'none'

interface drawer {
  drawer: status
  pedido?: IPedido
  caixa: ICaixa | undefined
}

interface Props {
  config: drawer
  setVisible: Dispatch<SetStateAction<drawer>>
}

export default function Pedido({
  config: { drawer, caixa, pedido },
  setVisible
}: Props): ReactElement {
  const queryClient = useQueryClient()
  const { watch, handleSubmit, reset, control } = useForm({ mode: 'onBlur' })
  const status = watch('status')
  const entrega = watch('entrega')
  const [waiting, setWaiting] = useState(false)
  const [editable, setEditable] = useState(false)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (pedido) {
      setWaiting(false)
      setEditable(false)
      reset(pedido)
      let valor = 0
      pedido.produtos.forEach(produto => {
        valor +=
          produto.quantidade * Number(produto.produto.valor) - produto.desconto
      })
      setTotal(valor)
    }
  }, [pedido])

  const onEdit = () =>
    handleSubmit(async data => {
      setWaiting(true)
      await api
        .put(`/pedido/${data.id}`, data)
        .then(() => {
          setWaiting(false)
          setEditable(false)
          message.success('Pedido atualizado com sucesso')
          queryClient.invalidateQueries('pedidos')
        })
        .catch(() => {
          setWaiting(false)
        })
    })()

  const onDelete = async (id: number) => {
    if (pedido) {
      setWaiting(true)
      await api
        .put(`/remove_produto/${id}`)
        .then(() => {
          setWaiting(false)
          queryClient.invalidateQueries('pedidos')
          setVisible({
            drawer: 'none',
            caixa,
            pedido: {
              ...pedido,
              produtos: pedido.produtos.filter(el => el.id !== id)
            }
          })
          message.success('Produto removido com sucesso!')
        })
        .finally(() => setWaiting(false))
    }
  }

  return (
    <Col span={14} style={{ background: '#F9F9F9', padding: 10 }}>
      <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
        <Title>Cliente: {pedido ? pedido.cliente.nome : ''} </Title>
      </Row>
      <Card property="nopadding">
        <Spin spinning={waiting}>
          <Col style={{ padding: 10 }}>
            <Title>
              Número do Pedido.: {pedido ? pedido.id : ''}
              <Tooltip title={editable ? 'Salvar' : 'Editar'}>
                <Button
                  shape="circle"
                  type="text"
                  disabled={status === 'Finalizado' || !caixa?.status}
                  icon={editable ? <SaveOutlined /> : <EditOutlined />}
                  onClick={editable ? onEdit : () => setEditable(true)}
                />
              </Tooltip>
            </Title>
            <Typography.Text type="secondary">{status || '-'}</Typography.Text>
          </Col>
          <Row
            style={{
              background: 'rgba(253, 197, 0, 0.15)',
              border: '1px solid #DDDDDD'
            }}
          >
            <Col span={8}>
              <Row align="middle" justify="center">
                <PieChartOutlined
                  style={{ fontSize: 36, color: '#FDC500', marginRight: 5 }}
                />
                <Col>
                  <Title>{status || '-'}</Title>
                  <Typography.Text type="secondary">
                    Status do Pedido
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row align="middle" justify="center">
                <HistoryOutlined
                  style={{ fontSize: 36, color: '#FDC500', marginRight: 5 }}
                />
                <Col>
                  <Title>
                    {entrega ? format(parseISO(entrega), 'dd/MM/yyyy') : '--'}
                  </Title>
                  <Typography.Text type="secondary">
                    Data de Entrega
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row align="middle" justify="center">
                <DollarOutlined
                  style={{ fontSize: 36, color: '#FDC500', marginRight: 5 }}
                />
                <Col>
                  <Title>{formatarMoeda(total)}</Title>
                  <Typography.Text type="secondary">
                    Valor Total
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col span={24} style={{ padding: 10 }}>
            <Label>OBSERVAÇÕES</Label>
            <Input.TextArea
              name="observacoes"
              control={control}
              autoSize
              disabled={!editable}
            />
          </Col>
          <Divisoria />
          <Table
            dataSource={pedido?.produtos || ([] as IPedidoProdutos[])}
            rowKey={record => record.id}
          >
            <Column<IPedidoProdutos>
              title="ADICIONAL"
              dataIndex="produto"
              key="produto"
              render={(_, record) => record.produto.nome}
            />
            <Column<IPedidoProdutos>
              title="UNIDADE"
              key="unidade"
              render={(_, record) => record.produto.unidade.unidade}
            />
            <Column title="QTDE" dataIndex="quantidade" key="quantidade" />
            <Column<IPedidoProdutos>
              title="VALOR"
              key="valor"
              render={(_, record) => formatarMoeda(record.produto.valor)}
            />
            <Column<IPedidoProdutos>
              title="DESCONTO"
              dataIndex="desconto"
              key="desconto"
              render={value => formatarMoeda(value)}
            />
            <Column<IPedidoProdutos>
              title="VALOR TOTAL"
              key="total"
              render={(_, record) => {
                const total =
                  Number(record.produto.valor) * record.quantidade -
                  record.desconto
                return formatarMoeda(total)
              }}
            />
            <Column<IPedidoProdutos>
              key="action"
              render={(_, { id }) => (
                <Tooltip title="Apagar" placement="top">
                  <Popconfirm
                    title="Deseja realmente apagar este registro?"
                    okText="Sim"
                    cancelText="Não"
                    disabled={status === 'Finalizado'}
                    onConfirm={() => onDelete(id)}
                  >
                    <Button
                      shape="circle"
                      type="text"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                </Tooltip>
              )}
            />
          </Table>
          <Row justify="end" style={{ padding: 15 }}>
            <Button
              type="dashed"
              size="large"
              block
              icon={<PlusOutlined />}
              disabled={
                status === 'Finalizado' || !caixa?.status || drawer === 'none'
              }
              onClick={() => setVisible({ drawer: 'service', caixa, pedido })}
            >
              NOVO ADICIONAL
            </Button>
          </Row>
          <Row justify="end" style={{ padding: 15 }}>
            <Button
              shape="round"
              type="primary"
              size="large"
              icon={<CheckOutlined />}
              disabled={
                status === 'Finalizado' || !caixa?.status || drawer === 'none'
              }
              onClick={() => setVisible({ drawer: 'payment', caixa, pedido })}
            >
              FINALIZAR
            </Button>
          </Row>
        </Spin>
      </Card>
      <AddAdicional
        config={{ drawer, caixa, pedido }}
        setVisible={setVisible}
      />
      <Pagamento config={{ drawer, caixa, pedido }} setVisible={setVisible} />
    </Col>
  )
}
