import React from 'react'
import { Avatar, Col, Divider, Layout, Menu, Row, Typography } from 'antd'
import { ReactComponent as Logo } from 'Assets/logo.svg'
import { Outlet } from 'react-router-dom'
import { IconFont } from 'components'
import styled from 'styled-components'
import { ToolOutlined, UserOutlined } from '@ant-design/icons'
import { useAuth } from 'Context/AuthContext'
import useRouter from 'Hooks/useRoutes'

const MenuContent = styled(Menu)``
const MenuItem = styled(Menu.Item)`
  font-size: 18px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  &:hover {
    background-color: #419fd9;
  }
`
const LogoutButton = styled(Typography.Text)`
  &:hover {
    cursor: pointer;
  }
`

const LayoutRoutes: React.FC = () => {
  const { navigate } = useRouter()
  const { logout, user } = useAuth()
  return (
    <Layout style={{ height: '100%' }}>
      <Layout.Sider width={250} breakpoint="xl" collapsedWidth="0">
        <Row className="logo" justify="center" align="middle">
          <Logo />
        </Row>
        <Divider style={{ margin: '8px 0' }} />
        <MenuContent>
          <MenuItem
            key="painel"
            onClick={() => navigate('/')}
            icon={
              <IconFont
                type="icon-appstore"
                style={{
                  fontSize: 24
                }}
              />
            }
          >
            Painel
          </MenuItem>
          <MenuItem
            key="pedidos"
            disabled={!user?.cargo.pedidos.visualizar}
            icon={<IconFont type="icon-filetext" style={{ fontSize: 24 }} />}
            onClick={() => navigate('pedidos')}
          >
            Pedidos
          </MenuItem>
          <Menu.Divider
            style={{ margin: '8px 0', backgroundColor: 'rgba(0,0,0,0.1)' }}
          />
          {/* <MenuItem
            key="produtos"
            disabled={!user?.cargo.produtos.visualizar}
            icon={
              <IconFont type="icon-iconfontstocking" style={{ fontSize: 24 }} />
            }
          >
            Produtos
          </MenuItem> */}
          <MenuItem
            key="produtos"
            disabled={!user?.cargo.produtos.visualizar}
            icon={<IconFont type="icon-sticker" style={{ fontSize: 24 }} />}
            onClick={() => navigate('produtos')}
          >
            Produtos
          </MenuItem>
          <MenuItem
            key="clientes"
            disabled={!user?.cargo.clientes.visualizar}
            icon={<IconFont type="icon-icon_user" style={{ fontSize: 24 }} />}
            onClick={() => navigate('clientes')}
          >
            Clientes
          </MenuItem>
          <MenuItem
            key="fornecedores"
            disabled={!user?.cargo.fornecedores.visualizar}
            onClick={() => navigate('fornecedores')}
            icon={<IconFont type="icon-Store" style={{ fontSize: 24 }} />}
          >
            Fornecedores
          </MenuItem>
          <MenuItem
            key={8}
            disabled={!user?.cargo.unidades}
            icon={<ToolOutlined style={{ fontSize: 24 }} />}
            onClick={() => navigate('unidades')}
          >
            Unidades
          </MenuItem>
          <MenuItem
            key="usuarios"
            disabled={!user?.cargo.usuarios}
            icon={<IconFont type="icon-users" style={{ fontSize: 24 }} />}
            onClick={() => navigate('usuarios')}
          >
            Usuários
          </MenuItem>
          <MenuItem
            key="perfis"
            disabled={!user?.cargo.cargos.visualizar}
            icon={<IconFont type="icon-users" style={{ fontSize: 24 }} />}
            onClick={() => navigate('perfis')}
          >
            Perfis de Acesso
          </MenuItem>
          <Menu.Divider
            style={{ margin: '8px 0', backgroundColor: 'rgba(0,0,0,0.1)' }}
          />
          {/* <MenuItem
            key="compras"
            disabled={!user?.cargo.compras.visualizar}
            icon={
              <IconFont type="icon-shopping-bag" style={{ fontSize: 24 }} />
            }
          >
            Compras
          </MenuItem> */}
          <MenuItem
            key="pagar"
            disabled={!user?.cargo.aPagar.visualizar}
            icon={<IconFont type="icon-receipt" style={{ fontSize: 24 }} />}
            onClick={() => navigate('pagar')}
          >
            Contas a Pagar
          </MenuItem>
          <MenuItem
            key="receber"
            disabled={!user?.cargo.aReceber.visualizar}
            icon={<IconFont type="icon-Timealt2" style={{ fontSize: 24 }} />}
            onClick={() => navigate('receber')}
          >
            Contas a Receber
          </MenuItem>
          <MenuItem
            key="caixa"
            disabled={!user?.cargo.caixa.visualizar}
            icon={<IconFont type="icon-caixa" style={{ fontSize: 24 }} />}
            onClick={() => navigate('caixa')}
          >
            Controle de Caixa
          </MenuItem>
          <Menu.Divider
            style={{ margin: '8px 0', backgroundColor: 'rgba(0,0,0,0.1)' }}
          />
          {user?.empresa.plano === 'Admin' && (
            <MenuItem
              key="empresas"
              icon={<IconFont type="icon-Vector" style={{ fontSize: 24 }} />}
              onClick={() => navigate('empresas')}
            >
              Empresas
            </MenuItem>
          )}
        </MenuContent>
        <Row align="middle" style={{ padding: 12 }}>
          <Col span={8}>
            <Avatar size={48} icon={<UserOutlined />} />
          </Col>
          <Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text>{user?.nome}</Typography.Text>
            <LogoutButton
              type="secondary"
              onClick={async () => {
                await logout()
              }}
            >
              Sair
            </LogoutButton>
          </Col>
        </Row>
      </Layout.Sider>
      <Layout.Content
        style={{ padding: 10, background: '#FFF', maxHeight: '100%' }}
      >
        <Outlet />
      </Layout.Content>
    </Layout>
  )
}

export default LayoutRoutes
