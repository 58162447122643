import React, { useEffect, useState } from 'react'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  ToolOutlined
} from '@ant-design/icons'
import {
  Col,
  Row,
  Typography,
  Button,
  Table,
  message,
  Space,
  Tooltip,
  Popconfirm
} from 'antd'
import { Card, Container, InputText, Title } from 'components'
import { IUnidade, status } from 'types'
import api from 'services/api'
import Unidade from 'components/Drawer/Unidade'
import { useQueryClient } from 'react-query'
import { useUnidades } from 'Hooks/functions'

const { Column } = Table

interface drawer {
  drawer: status
  unidade?: IUnidade
}

const Unidades: React.FC = () => {
  const [drawer, setDrawer] = useState<drawer>({
    drawer: 'none'
  })
  const [filter, setFilter] = useState<IUnidade[]>([] as IUnidade[])
  const queryClient = useQueryClient()
  const { data: unidades, isFetching: loading } = useUnidades()

  const onDelete = async (id: number) => {
    await api.delete(`/unidade/${id}`).then(() => {
      queryClient.invalidateQueries('unidades')
      message.success('Unidade excluida com sucesso!')
    })
  }

  useEffect(() => {
    if (unidades) {
      setFilter(unidades)
    }
  }, [unidades])

  return (
    <Container>
      <Col span={24} style={{ padding: 10 }}>
        <Row justify="start" align="middle" style={{ margin: '10px 0' }}>
          <ToolOutlined style={{ fontSize: 24, marginRight: 10 }} />
          <Title>Unidades</Title>
        </Row>
        <Row style={{ margin: '10px 0' }}>
          <Typography.Text type="secondary">
            {unidades ? unidades.length : 0} registros
          </Typography.Text>
        </Row>
        <Row wrap={false} gutter={8} style={{ margin: '10px 0' }}>
          <Col span={16}>
            <InputText
              size="large"
              placeholder="Pesquisar"
              onChange={e => {
                if (unidades) {
                  setFilter(
                    unidades.filter(unidade =>
                      unidade.unidade
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  )
                }
              }}
              suffix={<SearchOutlined />}
            />
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              shape="round"
              size="large"
              icon={<PlusOutlined />}
              onClick={() =>
                setDrawer({
                  drawer: 'insert'
                })
              }
            >
              NOVO
            </Button>
          </Col>
        </Row>
        <Card property="nopadding" style={{ margin: '10px 0' }}>
          <Table
            dataSource={filter}
            loading={loading}
            rowKey={record => record.id}
          >
            <Column
              title="CÓD"
              dataIndex="id"
              key="id"
              responsive={['xxl', 'xl', 'lg', 'md']}
            />
            <Column title="UNIDADE" dataIndex="unidade" key="unidade" />
            <Column
              key="actions"
              render={(_, record: IUnidade) => (
                <Space>
                  <Tooltip title="Editar" placement="top">
                    <Button
                      shape="circle"
                      type="text"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setDrawer({
                          drawer: 'update',
                          unidade: record
                        })
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Apagar" placement="top">
                    <Popconfirm
                      title="Deseja realmente apagar este registro?"
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={() => onDelete(record.id)}
                    >
                      <Button
                        shape="circle"
                        type="text"
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </Tooltip>
                </Space>
              )}
            />
          </Table>
        </Card>
      </Col>
      <Unidade setVisible={setDrawer} config={drawer} />
    </Container>
  )
}

export default Unidades
