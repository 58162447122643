import React from 'react'
import { useAuth } from 'Context/AuthContext'
import Caixa from 'Pages/Caixa'
import Cargos from 'Pages/Cargos'
import Clientes from 'Pages/Clientes'
import Empresas from 'Pages/Empresas'
import Fornecedores from 'Pages/Fornecedores'
import Login from 'Pages/Login'
import Pagar from 'Pages/Pagar'
import Receber from 'Pages/Receber'
import Produtos from 'Pages/Produtos'
import Unidades from 'Pages/Unidades'
import Usuarios from 'Pages/Usuarios'
import { Route, Routes } from 'react-router-dom'
import LayoutRoutes from './layout.routes'
import Recuperar from 'Pages/Recuperar'
import PrivateRoute from './private.routes'
import Pedidos from 'Pages/Pedidos'
import Loading from 'components/Loading'
import Dashboard from 'Pages/Dashboard'

export default function AppRoutes() {
  const { user, signed, loading } = useAuth()

  if (loading) {
    return <Loading />
  }
  return (
    <Routes>
      {!signed ? (
        <>
          <Route path="/" element={<Login />} />
          <Route path="/recuperar/:token" element={Recuperar} />
          <Route path="*" element={<Login />} />
        </>
      ) : (
        <Route path="/" element={<LayoutRoutes />}>
          <Route index element={<PrivateRoute element={<Dashboard />} />} />
          <Route
            path="pedidos"
            element={<PrivateRoute element={<Pedidos />} />}
          />
          <Route
            path="clientes"
            element={<PrivateRoute element={<Clientes />} />}
          />
          <Route
            path="fornecedores"
            element={<PrivateRoute element={<Fornecedores />} />}
          />
          <Route
            path="produtos"
            element={<PrivateRoute element={<Produtos />} />}
          />
          <Route
            path="unidades"
            element={<PrivateRoute element={<Unidades />} />}
          />
          {user?.empresa.plano === 'Admin' && (
            <Route
              path="empresas"
              element={<PrivateRoute element={<Empresas />} />}
            />
          )}
          <Route
            path="usuarios"
            element={<PrivateRoute element={<Usuarios />} />}
          />
          <Route path="caixa" element={<PrivateRoute element={<Caixa />} />} />
          <Route
            path="receber"
            element={<PrivateRoute element={<Receber />} />}
          />
          <Route path="pagar" element={<PrivateRoute element={<Pagar />} />} />
          <Route
            path="perfis"
            element={<PrivateRoute element={<Cargos />} />}
          />
        </Route>
      )}
    </Routes>
  )
}
