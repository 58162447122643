import React, { useState } from 'react'
import {
  Button,
  Col,
  Drawer,
  message,
  Row,
  Spin,
  Table,
  Typography
} from 'antd'
import {
  Container,
  IconFont,
  Title,
  Label,
  Divisoria,
  TitleSecondary,
  RoundButton
} from 'components'
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { formatarMoeda } from 'services/utils'
import ZigZag from 'Assets/zigzag.png'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import { ICaixa, IMovimentacao } from 'types'
import DatePicker from 'components/DatePicker'
import { format } from 'date-fns'
import { useQuery, useQueryClient } from 'react-query'
import Input from 'components/Controlled/Input'
import InputMasked from 'components/Controlled/InputMasked'

const { Column } = Table

type status = 'none' | 'entrada' | 'saida'

const Pages: React.FC = () => {
  const { control, reset, handleSubmit } = useForm({ mode: 'all' })
  const queryClient = useQueryClient()
  const [data, setData] = useState(new Date())
  const [totalEntradas, setTotalEntradas] = useState(0)
  const [totalSaidas, setTotalSaidas] = useState(0)
  const [revelar, setRevelar] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [drawer, setDrawer] = useState<status>('none')
  const converted = format(new Date(), 'yyyy-MM-dd')

  const { data: caixa, isFetching } = useQuery<ICaixa>(
    'caixa',
    async () =>
      await api.get(`/verifica_caixa?data=${converted}`).then(res => res.data)
  )
  const { data: entradas, isFetching: entradaLoading } = useQuery<
    IMovimentacao[]
  >(
    ['entradas', data],
    async () =>
      await api
        .get(`/movimentacao?tipo=entrada&data=${format(data, 'yyyy-MM-dd')}`)
        .then(res => {
          let total = 0
          res.data.forEach(
            (entrada: IMovimentacao) => (total += Number(entrada.valor))
          )
          setTotalEntradas(total)
          return res.data
        })
  )
  const { data: saidas, isFetching: saidaLoading } = useQuery<IMovimentacao[]>(
    ['saidas', data],
    async () =>
      await api
        .get(`/movimentacao?tipo=saida&data=${format(data, 'yyyy-MM-dd')}`)
        .then(res => {
          let total = 0
          res.data.forEach(
            (saida: IMovimentacao) => (total += Number(saida.valor))
          )
          setTotalSaidas(total)

          return res.data
        })
  )

  const handleCaixa = async () => {
    setLoading(true)
    if (caixa) {
      if (caixa?.status === true) {
        onFecharCaixa(caixa.id)
      } else {
        onReabrirCaixa(caixa.id)
      }
    } else {
      onAbrirCaixa()
    }
  }

  const onFecharCaixa = async (id: number) => {
    await api
      .put(`/fechar/${id}`, {
        valor: totalEntradas - totalSaidas
      })
      .then(() => queryClient.invalidateQueries('caixa'))
      .finally(() => setLoading(false))
  }

  const onAbrirCaixa = async () => {
    await api
      .post('/abrir')
      .then(() => {
        queryClient.invalidateQueries('caixa')
        message.success('Caixa aberto com sucesso!')
      })
      .finally(() => setLoading(false))
  }

  const onReabrirCaixa = async (id: number) => {
    await api
      .put(`/reabrir/${id}`)
      .then(() => {
        message.success('Caixa reaberto com sucesso!')
        queryClient.invalidateQueries('caixa')
      })
      .finally(() => setLoading(false))
  }

  const onEntrada = async (data: any) => {
    await api
      .post('/movimentacao', {
        ...data,
        tipo: 'entrada',
        origem: 'outros'
      })
      .then(() => {
        message.success('Entrada lançada com sucesso!')
        queryClient.invalidateQueries('entradas')
        onClose()
      })
      .finally(() => setWaiting(false))
  }

  const onSaida = async (data: any) => {
    await api
      .post('/movimentacao', {
        ...data,
        tipo: 'saida',
        origem: 'outros'
      })
      .then(() => {
        message.success('Saída lançada com sucesso!')
        queryClient.invalidateQueries('saidas')
        onClose()
      })
      .finally(() => setWaiting(false))
  }

  const onSubmit = async () => {
    handleSubmit(async data => {
      setWaiting(true)
      if (drawer === 'entrada') {
        await onEntrada(data)
      }
      if (drawer === 'saida') {
        await onSaida(data)
      }
    })()
  }

  const onClose = () => {
    setDrawer('none')
    reset({})
    setWaiting(false)
  }

  return (
    <Spin spinning={entradaLoading || saidaLoading || isFetching}>
      <Container>
        <Col span={24} style={{ padding: 10 }}>
          <Row justify="start" align="middle" style={{ margin: '10px 0' }}>
            <IconFont
              type="icon-caixa"
              style={{ fontSize: 24, marginRight: 10 }}
            />
            <Title>Controle de Caixa</Title>
          </Row>
          <Row className="marginTop marginBottom">
            <DatePicker
              value={data}
              onChange={date => {
                if (date) {
                  setData(date)
                }
              }}
            />
          </Row>
          <Row className="cardStyle">
            <Col
              span={6}
              className="rightBorder"
              style={{ backgroundColor: '#F9F9F9' }}
            >
              <Row align="middle" style={{ padding: '1rem' }}>
                <Label>RESUMO</Label>
                <Button
                  type="text"
                  shape="circle"
                  style={{ marginLeft: '1rem' }}
                  icon={
                    revelar ? (
                      <EyeOutlined style={{ fontSize: 18 }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ fontSize: 18 }} />
                    )
                  }
                  onClick={() => setRevelar(!revelar)}
                />
              </Row>
              <Divisoria style={{ margin: 0 }} />
              <Col style={{ padding: '1rem' }}>
                <Col style={{ marginBottom: '1rem' }}>
                  <Row>{revelar ? formatarMoeda(totalEntradas) : '----'}</Row>
                  <Row>
                    <Typography.Text type="secondary">
                      Entradas do dia
                    </Typography.Text>
                  </Row>
                </Col>
                <Col>
                  <Row>{revelar ? formatarMoeda(totalSaidas) : '----'}</Row>
                  <Row>
                    <Typography.Text type="secondary">
                      Saídas do dia
                    </Typography.Text>
                  </Row>
                </Col>
              </Col>
              <Divisoria style={{ margin: 0 }} />
              <Col style={{ padding: '1rem' }}>
                <TitleSecondary color="#FDC500">
                  {revelar
                    ? formatarMoeda(totalEntradas - totalSaidas)
                    : '----'}
                </TitleSecondary>
                <Typography.Text>Saldo do dia</Typography.Text>
              </Col>
              <Row style={{ backgroundColor: '#FFF' }}>
                <img src={ZigZag} style={{ width: '100%' }} />
                <RoundButton
                  type="primary"
                  block
                  style={{ margin: '1rem' }}
                  onClick={handleCaixa}
                >
                  {caixa
                    ? caixa.status
                      ? 'FECHAR CAIXA'
                      : 'REABRIR CAIXA'
                    : 'ABRIR CAIXA'}
                </RoundButton>
              </Row>
            </Col>
            <Col span={18}>
              <Row gutter={12} wrap style={{ padding: '1rem' }}>
                <Col span={12}>
                  <Row align="middle" className="marginBottom">
                    <Col span={14}>
                      <Label>ENTRADAS</Label>
                      <Typography.Text type="secondary">
                        {entradas?.length} Entradas registradas
                      </Typography.Text>
                    </Col>
                    <Col span={10}>
                      <RoundButton
                        type="primary"
                        block
                        icon={<PlusOutlined />}
                        disabled={!caixa || !caixa.status}
                        onClick={() => setDrawer('entrada')}
                      >
                        ADICIONAR
                      </RoundButton>
                    </Col>
                  </Row>
                  <Col className="cardStyle">
                    <Table
                      dataSource={entradas}
                      rowKey={record => record.id}
                      loading={loading}
                      summary={() => {
                        return (
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              <Label>TOTAL</Label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              <Label>{formatarMoeda(totalEntradas)}</Label>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )
                      }}
                    >
                      <Column
                        title="ENTRADA"
                        dataIndex="descricao"
                        key="descricao"
                      />
                      <Column
                        title="VALOR"
                        dataIndex="valor"
                        key="valor"
                        render={text => formatarMoeda(text)}
                      />
                    </Table>
                  </Col>
                </Col>
                <Col span={12}>
                  <Row align="middle" className="marginBottom">
                    <Col span={14}>
                      <Label>SAÍDAS</Label>
                      <Typography.Text type="secondary">
                        {saidas?.length} Saídas registradas
                      </Typography.Text>
                    </Col>
                    <Col span={10}>
                      <RoundButton
                        type="primary"
                        block
                        disabled={!caixa || !caixa.status}
                        icon={<PlusOutlined />}
                        onClick={() => setDrawer('saida')}
                      >
                        ADICIONAR
                      </RoundButton>
                    </Col>
                  </Row>
                  <Col className="cardStyle">
                    <Table
                      dataSource={saidas}
                      rowKey={record => record.id}
                      loading={loading}
                      summary={() => {
                        return (
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              <Label>TOTAL</Label>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              <Label>{formatarMoeda(totalSaidas)}</Label>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )
                      }}
                    >
                      <Column
                        title="SAÍDA"
                        dataIndex="descricao"
                        key="descricao"
                      />
                      <Column
                        title="VALOR"
                        dataIndex="valor"
                        key="valor"
                        render={text => formatarMoeda(text)}
                      />
                    </Table>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Drawer
          visible={drawer !== 'none'}
          destroyOnClose
          onClose={onClose}
          width={400}
        >
          <Row justify="start" align="middle" style={{ margin: 10 }}>
            <IconFont
              type="icon-filetext"
              style={{ fontSize: 24, marginRight: 15 }}
            />
            {drawer === 'entrada' && <Title>Nova Entrada</Title>}
            {drawer === 'saida' && <Title>Nova Saída</Title>}
          </Row>
          <Spin spinning={waiting}>
            <Col span={24} className="marginTop marginBottom">
              <Label>DESCRIÇÃO</Label>
              <Input.TextArea
                control={control}
                name="descricao"
                rules={{ required: 'A descrição precisa ser informada' }}
                autoSize
              />
            </Col>

            <Col span={24}>
              <Label>VALOR</Label>
              <InputMasked.Money
                control={control}
                name="valor"
                rules={{ required: 'O valor precisa ser informado' }}
              />
            </Col>
            <Row align="middle" gutter={6} className="marginTop">
              <Col span={12}>
                <RoundButton block onClick={onClose}>
                  CANCELAR
                </RoundButton>
              </Col>
              <Col span={12}>
                <RoundButton block type="primary" onClick={onSubmit}>
                  CRIAR
                </RoundButton>
              </Col>
            </Row>
          </Spin>
        </Drawer>
      </Container>
    </Spin>
  )
}

export default Pages
