import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import GlobalStyle from './Styles/global'
import 'antd/dist/antd.less'
import AuthProvider from './Context/AuthContext'
import AppRoutes from 'Routes/app.routes'
import { QueryClientProvider } from 'react-query'
import { queryClient } from 'services/queryClient'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <AppRoutes />
        </QueryClientProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
