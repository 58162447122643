import React, { ReactElement } from 'react'
import { Checkbox as CheckBox, CheckboxProps } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import { ErrorText } from 'components'

function Checkbox(
  props: UseControllerProps & Omit<CheckboxProps, keyof UseControllerProps>
): ReactElement {
  const {
    field,
    fieldState: { error }
  } = useController(props)
  return (
    <>
      <CheckBox
        {...props}
        {...field}
        checked={field.value}
        defaultChecked={false}
      >
        {props.children}
      </CheckBox>
      {error && <ErrorText>{error}</ErrorText>}
    </>
  )
}

export default Checkbox
