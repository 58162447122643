import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react'
import { Drawer, Row, Spin, Col, Button, message } from 'antd'
import { IconFont, Title, Label } from 'components'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import { ICaixa, ICliente, IPedido } from 'types'
import Cliente from '../Cliente'
import Select from 'components/Controlled/Select'
import Input from 'components/Controlled/Input'
import { useQueryClient } from 'react-query'
import { useClientes } from 'Hooks/functions'

type status = 'insert' | 'update' | 'service' | 'payment' | 'none'

interface drawer {
  drawer: status
  pedido?: IPedido
  caixa: ICaixa | undefined
}

interface Props {
  config: drawer
  setVisible: Dispatch<SetStateAction<drawer>>
}

interface drawerCliente {
  drawer: 'insert' | 'update' | 'none'
  cliente?: ICliente
}

export default function Cadastro({
  config: { drawer, caixa },
  setVisible
}: Props): ReactElement {
  const queryClient = useQueryClient()
  const { data: clientes, isFetching } = useClientes()
  const { control, reset, handleSubmit } = useForm({ mode: 'onBlur' })
  const [waiting, setWaiting] = useState(false)
  const [drawerCliente, setDrawerCliente] = useState<drawerCliente>({
    drawer: 'none'
  })

  const onClose = () => {
    reset()
    setVisible({ drawer: 'none', caixa })
  }

  const onSave = async (data: any) => {
    setWaiting(true)
    try {
      await api.post('/pedido', data).then(({ data }) => {
        message.success('Pedido cadastrado com sucesso!')
        queryClient.invalidateQueries('pedidos')
        reset({})
        setVisible({ drawer: 'update', caixa, pedido: data })
      })
    } finally {
      setWaiting(false)
    }
  }

  return (
    <Drawer visible={drawer === 'insert'} onClose={onClose} width={400}>
      <Row justify="start" align="middle" style={{ margin: 10 }}>
        <IconFont
          type="icon-filetext"
          style={{ fontSize: 24, marginRight: 15 }}
        />
        <Title>Novo Pedido</Title>
      </Row>
      <Spin spinning={waiting || isFetching}>
        <Col span={24} style={{ margin: 10 }}>
          <Label>NOME DO CLIENTE</Label>
          <Select
            control={control}
            name="clienteId"
            rules={{ required: 'O cliente precisa ser informado' }}
            placeholder="Selecione..."
          >
            {clientes?.map(cliente => (
              <Select.Option value={cliente.id} key={cliente.id}>
                {cliente.nome}
              </Select.Option>
            ))}
          </Select>
          <Button
            block
            type="text"
            onClick={() =>
              setDrawerCliente({
                drawer: 'insert'
              })
            }
          >
            Adicionar Cliente
          </Button>
        </Col>
        <Col span={24} style={{ margin: 10 }}>
          <Label>OBSERVAÇÃO</Label>
          <Input.TextArea
            name="observacoes"
            control={control}
            autoSize={{ minRows: 3 }}
            placeholder="Ex.: Algum detalhe adicional"
          />
        </Col>
        <Row justify="space-between" gutter={8} style={{ margin: '10px 0' }}>
          <Col span={12}>
            <Button shape="round" size="large" block onClick={onClose}>
              CANCELAR
            </Button>
          </Col>
          <Col span={12}>
            <Button
              shape="round"
              type="primary"
              block
              size="large"
              onClick={() => {
                handleSubmit(onSave)()
              }}
            >
              CRIAR
            </Button>
          </Col>
        </Row>
      </Spin>
      <Cliente config={drawerCliente} setVisible={setDrawerCliente} />
    </Drawer>
  )
}
