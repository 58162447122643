import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import { Button, Col, Drawer, message, Row, Spin } from 'antd'
import { IconFont, Label, Title } from 'components'
import { useForm } from 'react-hook-form'
import { ICargo, status } from 'types'
import api from 'services/api'
import inicial from './reset.json'
import Input from 'components/Controlled/Input'
import Checkbox from 'components/Controlled/Checkbox'
import { useQueryClient } from 'react-query'

interface drawer {
  drawer: status
  cargo?: ICargo
}

interface Props {
  config: {
    drawer: status
    cargo?: ICargo
  }
  setVisible: Dispatch<SetStateAction<drawer>>
}

export default function CargoDrawer({
  config: { cargo, drawer },
  setVisible
}: Props): ReactElement {
  const [waiting, setWaiting] = useState(false)
  const { control, reset, handleSubmit } = useForm({
    mode: 'onBlur'
  })
  const queryClient = useQueryClient()

  useEffect(() => {
    if (drawer === 'update') {
      reset({ ...cargo })
    }
  }, [drawer])

  const onClose = () => {
    reset(inicial)
    setVisible({
      drawer: 'none'
    })
  }

  const onSave = async (data: any) => {
    setWaiting(true)
    try {
      await api.post('/cargo', data).then(() => {
        setWaiting(false)
        queryClient.invalidateQueries('cargos')
        onClose()
        message.success('Perfil cadastrado com sucesso!')
      })
    } catch (error) {
      setWaiting(false)
    }
  }

  const onUpdate = async (data: any) => {
    setWaiting(true)
    try {
      await api.put(`/cargo/${cargo?.id}`, data).then(() => {
        setWaiting(false)
        queryClient.invalidateQueries('cargos')
        message.success('Perfil cadastrado com sucesso!')
        onClose()
      })
    } catch (error) {
      setWaiting(false)
    }
  }

  return (
    <Drawer
      visible={drawer !== 'none'}
      destroyOnClose
      onClose={onClose}
      width={450}
      title={
        <Row justify="start" align="middle" style={{ margin: 10 }}>
          <IconFont
            type="icon-sticker"
            style={{ fontSize: 24, marginRight: 10 }}
          />
          <Title>
            {drawer === 'insert' ? 'Novo' : 'Editar'} Perfil de Acesso
          </Title>
        </Row>
      }
    >
      <Spin spinning={waiting}>
        <Row gutter={12}>
          <Col span={24}>
            <Label>Perfil de Acesso</Label>
            <Input
              name="nome"
              control={control}
              rules={{ required: 'O nome do perfil precisa ser informado' }}
              placeholder="Perfil de Acesso"
            />
          </Col>
          <Col span={24} style={{ marginTop: '1rem' }}>
            <Label>PERMISSÕES</Label>
          </Col>
          <Col className="marginBottom">
            <Label>PEDIDOS</Label>
            <Row>
              <Checkbox
                name="pedidos.criar"
                defaultValue={true}
                control={control}
              >
                Criar
              </Checkbox>

              <Checkbox
                name="pedidos.visualizar"
                defaultValue={true}
                control={control}
              >
                Visualizar
              </Checkbox>

              <Checkbox
                name="pedidos.atualizar"
                defaultValue={true}
                control={control}
              >
                Atualizar
              </Checkbox>

              <Checkbox
                name="pedidos.apagar"
                defaultValue={true}
                control={control}
              >
                Apagar
              </Checkbox>
            </Row>
          </Col>
          <Col className="marginBottom">
            <Label>PRODUTOS</Label>
            <Row>
              <Checkbox
                name="produtos.criar"
                defaultValue={true}
                control={control}
              >
                Criar
              </Checkbox>

              <Checkbox
                name="produtos.visualizar"
                defaultValue={true}
                control={control}
              >
                Visualizar
              </Checkbox>

              <Checkbox
                name="produtos.atualizar"
                defaultValue={true}
                control={control}
              >
                Atualizar
              </Checkbox>

              <Checkbox
                name="produtos.apagar"
                defaultValue={true}
                control={control}
              >
                Apagar
              </Checkbox>
            </Row>
          </Col>
          <Col className="marginBottom">
            <Label>ESTOQUE</Label>
            <Row>
              <Checkbox
                name="estoque.criar"
                defaultValue={true}
                control={control}
              >
                Criar
              </Checkbox>

              <Checkbox
                name="estoque.visualizar"
                defaultValue={true}
                control={control}
              >
                Visualizar
              </Checkbox>

              <Checkbox
                name="estoque.atualizar"
                defaultValue={true}
                control={control}
              >
                Atualizar
              </Checkbox>

              <Checkbox
                name="estoque.apagar"
                defaultValue={true}
                control={control}
              >
                Apagar
              </Checkbox>
            </Row>
          </Col>
          <Col className="marginBottom">
            <Label>CLIENTES</Label>
            <Row>
              <Checkbox
                name="clientes.criar"
                defaultValue={true}
                control={control}
              >
                Criar
              </Checkbox>

              <Checkbox
                name="clientes.visualizar"
                defaultValue={true}
                control={control}
              >
                Visualizar
              </Checkbox>

              <Checkbox
                name="clientes.atualizar"
                defaultValue={true}
                control={control}
              >
                Atualizar
              </Checkbox>

              <Checkbox
                name="clientes.apagar"
                defaultValue={true}
                control={control}
              >
                Apagar
              </Checkbox>
            </Row>
          </Col>
          <Col className="marginBottom">
            <Label>UNIDADES</Label>
            <Row>
              <Checkbox
                name="unidades.criar"
                defaultValue={true}
                control={control}
              >
                Criar
              </Checkbox>

              <Checkbox
                name="unidades.visualizar"
                defaultValue={true}
                control={control}
              >
                Visualizar
              </Checkbox>

              <Checkbox
                name="unidades.atualizar"
                defaultValue={true}
                control={control}
              >
                Atualizar
              </Checkbox>

              <Checkbox
                name="unidades.apagar"
                defaultValue={true}
                control={control}
              >
                Apagar
              </Checkbox>
            </Row>
          </Col>
          <Col className="marginBottom">
            <Label>FORNECEDORES</Label>
            <Row>
              <Checkbox
                name="fornecedores.criar"
                defaultValue={true}
                control={control}
              >
                Criar
              </Checkbox>

              <Checkbox
                name="fornecedores.visualizar"
                defaultValue={true}
                control={control}
              >
                Visualizar
              </Checkbox>

              <Checkbox
                name="fornecedores.atualizar"
                defaultValue={true}
                control={control}
              >
                Atualizar
              </Checkbox>

              <Checkbox
                name="fornecedores.apagar"
                defaultValue={true}
                control={control}
              >
                Apagar
              </Checkbox>
            </Row>
          </Col>
          <Col className="marginBottom">
            <Label>USUÁRIOS</Label>
            <Row>
              <Checkbox
                name="usuarios.criar"
                defaultValue={true}
                control={control}
              >
                Criar
              </Checkbox>

              <Checkbox
                name="usuarios.visualizar"
                defaultValue={true}
                control={control}
              >
                Visualizar
              </Checkbox>

              <Checkbox
                name="usuarios.atualizar"
                defaultValue={true}
                control={control}
              >
                Atualizar
              </Checkbox>

              <Checkbox
                name="usuarios.apagar"
                defaultValue={true}
                control={control}
              >
                Apagar
              </Checkbox>
            </Row>
          </Col>
          <Col className="marginBottom">
            <Label>PERFIS DE ACESSO</Label>
            <Row>
              <Checkbox
                name="cargos.criar"
                defaultValue={true}
                control={control}
              >
                Criar
              </Checkbox>

              <Checkbox
                name="cargos.visualizar"
                defaultValue={true}
                control={control}
              >
                Visualizar
              </Checkbox>

              <Checkbox
                name="cargos.atualizar"
                defaultValue={true}
                control={control}
              >
                Atualizar
              </Checkbox>

              <Checkbox
                name="cargos.apagar"
                defaultValue={true}
                control={control}
              >
                Apagar
              </Checkbox>
            </Row>
          </Col>
          <Col className="marginBottom">
            <Label>CAIXA</Label>
            <Row>
              <Checkbox
                name="caixa.criar"
                defaultValue={true}
                control={control}
              >
                Criar
              </Checkbox>

              <Checkbox
                name="caixa.visualizar"
                defaultValue={true}
                control={control}
              >
                Visualizar
              </Checkbox>

              <Checkbox
                name="caixa.atualizar"
                defaultValue={true}
                control={control}
              >
                Atualizar
              </Checkbox>

              <Checkbox
                name="caixa.apagar"
                defaultValue={true}
                control={control}
              >
                Apagar
              </Checkbox>
            </Row>
          </Col>
          <Col className="marginBottom">
            <Label>CONTAS A PAGAR</Label>
            <Row>
              <Checkbox
                name="aPagar.criar"
                defaultValue={true}
                control={control}
              >
                Criar
              </Checkbox>

              <Checkbox
                name="aPagar.visualizar"
                defaultValue={true}
                control={control}
              >
                Visualizar
              </Checkbox>

              <Checkbox
                name="aPagar.atualizar"
                defaultValue={true}
                control={control}
              >
                Atualizar
              </Checkbox>

              <Checkbox
                name="aPagar.apagar"
                defaultValue={true}
                control={control}
              >
                Apagar
              </Checkbox>
            </Row>
          </Col>
          <Col className="marginBottom">
            <Label>CONTAS A RECEBER</Label>
            <Row>
              <Checkbox
                name="aReceber.criar"
                defaultValue={true}
                control={control}
              >
                Criar
              </Checkbox>
              <Checkbox
                name="aReceber.visualizar"
                defaultValue={true}
                control={control}
              >
                Visualizar
              </Checkbox>
              <Checkbox
                name="aReceber.atualizar"
                defaultValue={true}
                control={control}
              >
                Atualizar
              </Checkbox>
              <Checkbox
                name="aReceber.apagar"
                defaultValue={true}
                control={control}
              >
                Apagar
              </Checkbox>
            </Row>
          </Col>
          <Col span={12}>
            <Button shape="round" size="large" block onClick={onClose}>
              CANCELAR
            </Button>
          </Col>
          <Col span={12}>
            {drawer === 'insert' ? (
              <Button
                shape="round"
                type="primary"
                block
                size="large"
                onClick={() => handleSubmit(onSave)()}
              >
                CRIAR
              </Button>
            ) : (
              <Button
                shape="round"
                type="primary"
                block
                size="large"
                onClick={() => handleSubmit(onUpdate)()}
              >
                Salvar
              </Button>
            )}
          </Col>
        </Row>
      </Spin>
    </Drawer>
  )
}
