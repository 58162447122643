import React, { ReactElement } from 'react'
import { Select as SelectCombo } from 'antd'
import { useController, UseControllerProps } from 'react-hook-form'
import { SelectProps, SelectValue } from 'antd/lib/select'
import { ErrorText } from 'components'

export function Select(
  props: UseControllerProps &
    Omit<SelectProps<SelectValue>, keyof UseControllerProps>
): ReactElement {
  const {
    field,
    fieldState: { error }
  } = useController(props)
  return (
    <>
      <SelectCombo {...props} style={{ width: '100%' }} {...field} size="large">
        {props.children}
      </SelectCombo>
      {error && <ErrorText>{error.message}</ErrorText>}
    </>
  )
}

Select.Option = SelectCombo.Option
Select.OptGroup = SelectCombo.OptGroup
export default Select
