import { format } from 'date-fns'
import { useQuery } from 'react-query'
import api from 'services/api'
import {
  ICaixa,
  ICargo,
  ICliente,
  IEmpresa,
  IFornecedor,
  IPagar,
  IPedido,
  IProduto,
  IProgresso,
  IReceber,
  IUnidade,
  IUsuario
} from 'types'

/* Pedidos */
export async function getPedidos(): Promise<IPedido[]> {
  const { data } = await api.get('/pedido')
  return data
}
export function usePedidos() {
  const data = useQuery('pedidos', getPedidos)
  return data
}

export async function getPedidoById(id: number): Promise<IPedido> {
  const { data } = await api.get(`/pedido/${id}`)
  return data.data
}

export function usePedido(id: number) {
  const data = useQuery(['pedido', id], () => getPedidoById(id))
  return data
}

/* Usuarios */
export async function getUsuarios(): Promise<IUsuario[]> {
  const { data } = await api.get('/usuario')
  return data
}
export function useUsuarios() {
  const data = useQuery('usuarios', getUsuarios)
  return data
}
export async function getUsuarioById(id: number): Promise<IUsuario> {
  const { data } = await api.get(`/usuario/${id}`)
  return data
}
export function useUsuario(id: number) {
  const data = useQuery(['usuario', id], () => getUsuarioById(id))
  return data
}

/* Empresas */
export async function getEmpresas(): Promise<IEmpresa[]> {
  const { data } = await api.get('/empresa')
  return data
}
export function useEmpresas() {
  const data = useQuery('empresas', getEmpresas)
  return data
}
export async function getEmpresaById(id: number): Promise<IEmpresa> {
  const { data } = await api.get(`/empresa/${id}`)
  return data
}
export function useEmpresa(id: number) {
  const data = useQuery(['empresa', id], () => getEmpresaById(id))
  return data
}

/* Fornecedores */
export async function getFornecedores(): Promise<IFornecedor[]> {
  const { data } = await api.get('/fornecedor')
  return data
}
export function useFornecedores() {
  const data = useQuery('fornecedores', getFornecedores)
  return data
}
export async function getFornecedorById(id: number): Promise<IFornecedor> {
  const { data } = await api.get(`/fornecedor/${id}`)
  return data
}
export function useFornecedor(id: number) {
  const data = useQuery(['fornecedor', id], () => getFornecedorById(id))
  return data
}

/* Cargos */
export async function getCargos(): Promise<ICargo[]> {
  const { data } = await api.get('/cargo')
  return data
}
export function useCargos() {
  const data = useQuery('cargos', getCargos)
  return data
}
export async function getCargoById(id: number): Promise<ICargo> {
  const { data } = await api.get(`/cargo/${id}`)
  return data
}
export function useCargo(id: number) {
  const data = useQuery(['cargo', id], () => getCargoById(id))
  return data
}

/* Unidades */
export async function getUnidades(): Promise<IUnidade[]> {
  const { data } = await api.get('/unidade')
  return data
}
export function useUnidades() {
  const data = useQuery('unidades', getUnidades)
  return data
}
export async function getUnidadeById(id: number): Promise<IUnidade> {
  const { data } = await api.get(`/unidade/${id}`)
  return data
}
export function useUnidade(id: number) {
  const data = useQuery(['unidade', id], () => getUnidadeById(id))
  return data
}

/* Clientes */
export async function getClientes(): Promise<ICliente[]> {
  const { data } = await api.get('/cliente')
  return data
}
export function useClientes() {
  const data = useQuery('clientes', getClientes)
  return data
}
export async function getClienteById(id: number): Promise<ICliente> {
  const { data } = await api.get(`/cliente/${id}`)
  return data
}
export function useCliente(id: number) {
  const data = useQuery(['cliente', id], () => getClienteById(id))
  return data
}

/* Produtos */
export async function getProdutos(): Promise<IProduto[]> {
  const { data } = await api.get('/produto')
  return data
}
export function useProdutos() {
  const data = useQuery('produtos', getProdutos)
  return data
}
export async function getProdutoById(id: number): Promise<IProduto> {
  const { data } = await api.get(`/produto/${id}`)
  return data
}
export function useProduto(id: number) {
  const data = useQuery(['produto', id], () => getProdutoById(id))
  return data
}

/* Receber */
export async function getReceber(): Promise<IReceber[]> {
  const { data } = await api.get('/receber')
  return data
}
export function useReceber() {
  const data = useQuery('receber', getReceber)
  return data
}
export async function getReceberById(id: number): Promise<IReceber> {
  const { data } = await api.get(`/receber/${id}`)
  return data
}
export function useReceberById(id: number) {
  const data = useQuery(['receber', id], () => getReceberById(id))
  return data
}

/* Pagamento */
export async function getPagamento(): Promise<IPagar[]> {
  const { data } = await api.get('/pagamento')
  return data
}
export function usePagamento() {
  const data = useQuery('pagamento', getPagamento)
  return data
}
export async function getPagamentoById(id: number): Promise<IPagar> {
  const { data } = await api.get(`/pagamento/${id}`)
  return data
}
export function usePagamentoById(id: number) {
  const data = useQuery(['pagamento', id], () => getPagamentoById(id))
  return data
}

/* Caixa */
export async function getCaixa(): Promise<ICaixa[]> {
  const { data } = await api.get('/caixa')
  return data
}
export function useCaixa() {
  const data = useQuery('caixa', getCaixa)
  return data
}
export async function verificaCaixa(): Promise<ICaixa> {
  const converted = format(new Date(), 'yyyy-MM-dd')
  const { data } = await api.get(`/verifica_caixa?data=${converted}`)
  return data
}
export function useCaixaToday() {
  const data = useQuery('caixa', verificaCaixa)
  return data
}

/* Dashboard */
export async function getProgresso(): Promise<IProgresso[]> {
  const { data } = await api.get('/dashboard')
  return data
}
export function useProgresso() {
  const data = useQuery('dashboard', getProgresso)
  return data
}
