import React, { useState } from 'react'
import styled from 'styled-components'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons'
import { Row, Col, Typography, Button, message, Spin } from 'antd'
import api from 'services/api'
import { useAuth } from 'Context/AuthContext'
import { ReactComponent as Logo } from 'Assets/logo.svg'
import { Divisoria } from 'components'
import useRouter from 'Hooks/useRoutes'
import Input from 'components/Controlled/Input'

const { Text } = Typography

const Background = styled(Row)`
  height: 100vh;
  background-image: linear-gradient(to bottom right, #419fd9, #fff);
`
const LoginText = styled.h3`
  margin: 0;
  color: #419fd9;
`

const LoginCard = styled(Col)`
  background-color: #fff;
  padding: 3em;
  box-shadow: 0px 10px 20px -5px rgba(40, 112, 153, 0.25),
    0px 0px 80px -10px rgba(40, 112, 153, 0.25);
  border-radius: 15px;
`
const LoginForm = styled(Row)`
  margin-top: 20px;
`

type Formulario = 'login' | 'recuperar'

const Login: React.FC = () => {
  const { login } = useAuth()
  const { navigate } = useRouter()
  const [loading, setLoading] = useState(false)
  const [formulario, setFormulario] = useState<Formulario>('login')
  const { control, handleSubmit, reset } = useForm({ mode: 'all' })

  const onRecuperar = async (data: any) => {
    setLoading(true)
    try {
      await api.post('/recuperar', { email: data.email }).then(() => {
        message.success('Você receberá um email com as instruções a seguir!')
        reset({})
        setFormulario('login')
      })
    } finally {
      setLoading(false)
    }
  }

  const onLogin: SubmitHandler<any> = async data => {
    setLoading(true)
    try {
      await login(data)
      navigate('/pedidos')
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Background align="middle" justify="center">
      <LoginCard xxl={5} xl={7} lg={10} md={12} xs={22}>
        <Row justify="center">
          <Logo />
        </Row>

        <Spin spinning={loading}>
          <LoginForm gutter={[12, 12]}>
            <LoginText>
              {formulario === 'recuperar' ? 'RECUPERAR SENHA' : 'LOGIN'}
            </LoginText>
            <Col span={24}>
              <Input.Email
                name="email"
                control={control}
                shouldUnregister
                suffix={<MailOutlined />}
              />
            </Col>
            {formulario === 'login' && (
              <>
                <Col span={24}>
                  <Input.Password
                    name="password"
                    placeholder="Senha"
                    control={control}
                    rules={{ required: 'A senha precisa ser informada' }}
                    shouldUnregister
                  />
                </Col>
                <Col span={24}>
                  <Row justify="space-between">
                    <Button
                      type="text"
                      onClick={() => setFormulario('recuperar')}
                    >
                      Recuperar Senha
                    </Button>
                    <Button
                      type="primary"
                      shape="round"
                      onClick={handleSubmit(onLogin)}
                    >
                      ENTRAR
                    </Button>
                  </Row>
                </Col>
              </>
            )}
            {formulario === 'recuperar' && (
              <Col span={24}>
                <Row
                  justify="space-between"
                  align="middle"
                  wrap={false}
                  gutter={[12, 12]}
                >
                  <Col flex={1}>
                    <Text type="secondary">
                      Um link será enviado para o seu e-mail
                    </Text>
                  </Col>
                  <Col flex={1}>
                    <Button
                      block
                      type="primary"
                      shape="round"
                      onClick={handleSubmit(onRecuperar)}
                    >
                      ENVIAR
                    </Button>
                  </Col>
                </Row>
                <Divisoria />
                <Row>
                  <Button
                    type="text"
                    style={{ color: '#7A356C' }}
                    icon={<ArrowLeftOutlined />}
                    onClick={() => setFormulario('login')}
                  >
                    VOLTAR PARA O LOGIN
                  </Button>
                </Row>
              </Col>
            )}
          </LoginForm>
        </Spin>
      </LoginCard>
    </Background>
  )
}

export default Login
