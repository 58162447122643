import React from 'react'
import { useAuth } from 'Context/AuthContext'
import { Navigate } from 'react-router-dom'
import { Spin } from 'antd'

interface Props {
  element: React.ReactElement
}

const PrivateRoute: React.FC<Props> = ({ element }) => {
  const { signed, loading } = useAuth()

  if (loading) {
    return <Spin />
  }
  return signed ? element : <Navigate to="/login" />
}
export default PrivateRoute
