import React from 'react'
import { InputProps, Input as InputText } from 'antd'
import { PasswordProps, TextAreaProps } from 'antd/lib/input'
import { useController, UseControllerProps } from 'react-hook-form'
import { ErrorText } from 'components'

function Input(
  props: UseControllerProps & Omit<InputProps, keyof UseControllerProps>
) {
  const {
    field,
    fieldState: { error }
  } = useController(props)
  return (
    <>
      <InputText {...props} {...field} size="large" className="mc-input" />
      {error && <ErrorText>{error.message}</ErrorText>}
    </>
  )
}

Input.Email = function Email(
  props: UseControllerProps & Omit<InputProps, keyof UseControllerProps>
) {
  const {
    field,
    fieldState: { error }
  } = useController({
    ...props,
    rules: {
      required: 'E-mail é obrigatório',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: 'Entre com um email válido'
      }
    }
  })
  return (
    <>
      <InputText
        {...props}
        {...field}
        size="large"
        placeholder="Email"
        className="mc-input"
      />
      {error && <ErrorText>{error.message}</ErrorText>}
    </>
  )
}

Input.TextArea = function Area(
  props: UseControllerProps & Omit<TextAreaProps, keyof UseControllerProps>
) {
  const {
    field,
    fieldState: { error }
  } = useController(props)
  return (
    <>
      <InputText.TextArea
        {...props}
        {...field}
        size="large"
        autoSize
        className="mc-input"
      />
      {error && <ErrorText>{error.message}</ErrorText>}
    </>
  )
}

Input.Password = function Password(
  props: UseControllerProps & Omit<PasswordProps, keyof UseControllerProps>
) {
  const {
    field,
    fieldState: { error }
  } = useController(props)
  return (
    <>
      <InputText.Password
        {...props}
        {...field}
        size="large"
        className="mc-input"
      />
      {error && <ErrorText>{error.message}</ErrorText>}
    </>
  )
}

export default Input
