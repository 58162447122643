import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import { ExclamationCircleOutlined, ToolOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Row, Modal, message, Spin } from 'antd'
import { Title, Label } from 'components'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import { IUnidade, status } from 'types'
import Input from 'components/Controlled/Input'
import { useQueryClient } from 'react-query'

const { confirm } = Modal

interface drawer {
  drawer: status
  unidade?: IUnidade
}

interface Props {
  config: {
    drawer: status
    unidade?: IUnidade
  }
  setVisible: Dispatch<SetStateAction<drawer>>
}

export default function Unidade({
  config: { drawer, unidade },
  setVisible
}: Props): ReactElement {
  const { control, reset, handleSubmit, formState } = useForm({
    mode: 'onBlur'
  })
  const [waiting, setWaiting] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (drawer === 'update' && unidade) {
      reset(unidade)
    }
  }, [drawer])

  const onClose = () => {
    if (formState.touchedFields) {
      confirm({
        title: 'Você deseja realmente sair?',
        icon: <ExclamationCircleOutlined />,
        content: 'Essa ação é irreversível após a confirmação',
        okText: 'Confirmar',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: () => {
          reset({})
          setVisible({
            drawer: 'none'
          })
        }
      })
    } else {
      reset({})
      setVisible({
        drawer: 'none'
      })
    }
  }

  const onSave = async (data: any) => {
    setWaiting(true)
    try {
      await api.post('/unidade', data).then(() => {
        setWaiting(false)
        queryClient.invalidateQueries('unidades')
        message.success('Unidade cadastrado com sucesso!')
        reset({})
        setVisible({
          drawer: 'none'
        })
      })
    } catch (error) {
      setWaiting(false)
    }
  }

  const onUpdate = async (data: any) => {
    setWaiting(true)
    try {
      await api.put(`/unidade/${unidade?.id}`, data).then(() => {
        setWaiting(false)
        queryClient.invalidateQueries('unidades')
        reset({})
        setVisible({
          drawer: 'none'
        })
        message.success('Unidade atualizada com sucesso!')
      })
    } catch (error) {
      setWaiting(false)
    }
  }

  return (
    <Drawer
      visible={drawer !== 'none'}
      onClose={onClose}
      destroyOnClose={true}
      width={400}
    >
      <Spin spinning={waiting}>
        <Row justify="start" align="middle" style={{ margin: 10 }}>
          <ToolOutlined style={{ fontSize: 24, marginRight: 15 }} />
          <Title>{drawer === 'insert' ? 'Nova' : 'Editar'} Unidade</Title>
        </Row>
        <Col span={24} style={{ margin: '10px 0' }}>
          <Label>NOME DA UNIDADE</Label>
          <Input
            name="unidade"
            control={control}
            placeholder="Nome"
            rules={{
              required: 'A unidade precisa ser informada'
            }}
          />
        </Col>
        <Row justify="space-between" gutter={8} style={{ margin: '10px 0' }}>
          <Col span={12}>
            <Button shape="round" size="large" block onClick={onClose}>
              CANCELAR
            </Button>
          </Col>
          <Col span={12}>
            <Button
              shape="round"
              type="primary"
              block
              size="large"
              onClick={() => {
                drawer === 'update'
                  ? handleSubmit(onUpdate)()
                  : handleSubmit(onSave)()
              }}
            >
              {drawer === 'insert' ? 'CRIAR' : 'SALVAR'}
            </Button>
          </Col>
        </Row>
      </Spin>
    </Drawer>
  )
}
