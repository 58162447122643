import React from 'react'
import { Col } from 'antd'
import Progresso from 'components/Charts/Progresso'
import { Container } from 'components'

function Dashboard() {
  return (
    <Container>
      <Col span={8}>
        <Progresso />
      </Col>
      <Col span={16} />
    </Container>
  )
}

export default Dashboard
