import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import { Drawer, Spin, Row, Col, Button, message, Modal } from 'antd'
import { IconFont, Title, Label } from 'components'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import { IProduto, IUnidade, status } from 'types'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import Unidade from './Unidade'
import Input from 'components/Controlled/Input'
import Select from 'components/Controlled/Select'
import InputMasked from 'components/Controlled/InputMasked'
import { useQueryClient } from 'react-query'
import { useUnidades } from 'Hooks/functions'

const { confirm } = Modal

interface drawer {
  drawer: status
  adicional?: IProduto
}

interface Props {
  config: {
    drawer: status
    adicional?: IProduto
  }
  setVisible: Dispatch<SetStateAction<drawer>>
}

interface uniDraw {
  drawer: status
  unidade?: IUnidade
}

export default function Produto({
  config: { drawer, adicional },
  setVisible
}: Props): ReactElement {
  const { control, reset, handleSubmit, formState } = useForm({
    mode: 'onBlur'
  })
  const [uniDraw, setUniDraw] = useState<uniDraw>({
    drawer: 'none'
  })
  const [waiting, setWaiting] = useState(false)
  const queryClient = useQueryClient()
  const { data: unidades, isFetching: loading } = useUnidades()

  useEffect(() => {
    if (drawer === 'update' && adicional) {
      reset(adicional)
    }
  }, [drawer])

  const onClose = () => {
    if (formState.touchedFields) {
      confirm({
        title: 'Você deseja realmente sair?',
        icon: <ExclamationCircleOutlined />,
        content: 'Essa ação é irreversível após a confirmação',
        okText: 'Confirmar',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: () => {
          reset({})
          setVisible({
            drawer: 'none'
          })
        }
      })
    } else {
      reset({})
      setVisible({
        drawer: 'none'
      })
    }
  }

  const onSave = async (data: any) => {
    setWaiting(true)
    try {
      await api.post('/produto', data).then(() => {
        setWaiting(false)
        reset({})
        queryClient.invalidateQueries('produtos')
        setVisible({
          drawer: 'none'
        })
        message.success('Produto cadastrado com sucesso!')
      })
    } catch (error) {
      setWaiting(false)
    }
  }

  const onUpdate = async (data: any) => {
    setWaiting(true)
    try {
      await api.put(`/produto/${adicional?.id}`, data).then(() => {
        setWaiting(false)
        queryClient.invalidateQueries('produtos')
        onClose()
        message.success('Produto atualizado com sucesso!')
      })
    } catch (error) {
      setWaiting(false)
    }
  }

  return (
    <Drawer
      visible={drawer !== 'none'}
      destroyOnClose
      onClose={onClose}
      width={400}
    >
      <Spin spinning={waiting || loading}>
        <Row justify="start" align="middle" style={{ margin: 10 }}>
          <IconFont
            type="icon-sticker"
            style={{ fontSize: 24, marginRight: 10 }}
          />
          <Title>{drawer === 'insert' ? 'Novo' : 'Editar'} Produto</Title>
        </Row>
        <Col span={24} style={{ margin: '10px 0' }}>
          <Label>PRODUTO</Label>
          <Input
            name="nome"
            control={control}
            rules={{ required: 'O nome do produto precisa ser informado' }}
            placeholder="Nome do Produto"
          />
        </Col>
        <Col span={24} style={{ margin: '10px 0' }}>
          <Label>DESCRIÇÃO</Label>
          <Input.TextArea
            name="descricao"
            control={control}
            autoSize
            placeholder="Descrição"
          />
        </Col>
        <Col span={24} style={{ margin: '10px 0' }}>
          <Label>UNIDADE</Label>
          <Select
            name="unidadeId"
            control={control}
            rules={{ required: 'A unidade precisa ser informada' }}
            placeholder="Selecione..."
          >
            {unidades?.map(({ id, unidade }) => (
              <Select.Option key={id} value={id}>
                {unidade}
              </Select.Option>
            ))}
          </Select>
          <Button
            block
            type="text"
            onClick={() =>
              setUniDraw({
                drawer: 'insert'
              })
            }
          >
            Adicionar Unidade
          </Button>
        </Col>
        <Col span={24} style={{ margin: '10px 0' }}>
          <Label>VALOR</Label>
          <InputMasked.Money
            name="valor"
            control={control}
            rules={{ required: 'O valor do produto precisa ser informado' }}
            placeholder="Valor"
          />
        </Col>
        <Row justify="space-between" gutter={8} style={{ margin: '10px 0' }}>
          <Col span={12}>
            <Button shape="round" size="large" block onClick={onClose}>
              CANCELAR
            </Button>
          </Col>
          <Col span={12}>
            <Button
              shape="round"
              type="primary"
              block
              size="large"
              onClick={() => {
                drawer === 'update'
                  ? handleSubmit(onUpdate)()
                  : handleSubmit(onSave)()
              }}
            >
              {drawer === 'insert' ? 'CRIAR' : 'SALVAR'}
            </Button>
          </Col>
        </Row>
      </Spin>
      <Unidade config={uniDraw} setVisible={setUniDraw} />
    </Drawer>
  )
}
