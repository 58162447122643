import React, { ReactElement, useEffect, useState } from 'react'
import { Button, Col, Drawer, message, Row, Spin, Modal } from 'antd'
import { IconFont, Label, Title } from 'components'
import { useForm } from 'react-hook-form'
import { ICliente, status } from 'types'
import { MaskPhone } from 'services/utils'
import api from 'services/api'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import InputMasked from 'components/Controlled/InputMasked'
import Input from 'components/Controlled/Input'
import { useQueryClient } from 'react-query'

const { confirm } = Modal

interface drawer {
  drawer: status
  cliente?: ICliente
}

interface Props {
  config: {
    drawer: status
    cliente?: ICliente
  }
  setVisible: React.Dispatch<React.SetStateAction<drawer>>
}

export default function Cliente({
  config: { drawer, cliente },
  setVisible
}: Props): ReactElement {
  const { control, reset, handleSubmit, formState } = useForm({
    mode: 'all'
  })

  const [waiting, setWaiting] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (drawer === 'update' && cliente) {
      reset({
        ...cliente,
        telefone: MaskPhone(cliente.telefone)
      })
    } else {
      reset({})
    }
  }, [drawer])

  const onClose = () => {
    if (formState.touchedFields) {
      confirm({
        title: 'Você deseja realmente sair?',
        icon: <ExclamationCircleOutlined />,
        content: 'Essa ação é irreversível após a confirmação',
        okText: 'Confirmar',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: () => {
          reset({})
          setVisible({
            drawer: 'none'
          })
        }
      })
    } else {
      reset({})
      setVisible({
        drawer: 'none'
      })
    }
  }

  const onSave = async (data: any) => {
    setWaiting(true)
    try {
      await api.post('/cliente', data).then(() => {
        setWaiting(false)
        queryClient.invalidateQueries('clientes')
        reset({})
        setVisible({
          drawer: 'none'
        })
        message.success('Cliente cadastrado com sucesso!')
      })
    } catch (error) {
      setWaiting(false)
    }
  }

  const onUpdate = async (data: any) => {
    setWaiting(true)
    try {
      await api.put(`/cliente/${cliente?.id}`, data).then(() => {
        setWaiting(false)
        queryClient.invalidateQueries('clientes')
        reset({})
        onClose()
        message.success('Cliente atualizado com sucesso!')
      })
    } catch (error) {
      setWaiting(false)
    }
  }

  return (
    <Drawer
      destroyOnClose
      visible={drawer !== 'none'}
      onClose={onClose}
      width={400}
      title={
        <Row justify="start" align="middle">
          <IconFont
            type="icon-icon_user"
            style={{ fontSize: 24, marginRight: 15 }}
          />
          <Title>Novo Cliente</Title>
        </Row>
      }
    >
      <Spin spinning={waiting}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Label>{'NOME'}</Label>
            <Input
              name="nome"
              control={control}
              rules={{ required: 'O nome precisa ser informado' }}
              placeholder={'NOME'}
            />
          </Col>

          <Col span={24}>
            <Label>TELEFONE</Label>
            <InputMasked.Telefone
              name="telefone"
              placeholder="Telefone"
              control={control}
              rules={{ required: 'O telefone precisa ser informado' }}
            />
          </Col>
          <Col span={24}>
            <Label>EMAIL</Label>
            <Input.Email name="email" control={control} />
          </Col>
          <Col span={12}>
            <Button shape="round" size="large" block onClick={onClose}>
              CANCELAR
            </Button>
          </Col>
          <Col span={12}>
            {drawer === 'insert' ? (
              <Button
                shape="round"
                type="primary"
                block
                size="large"
                onClick={() => handleSubmit(onSave)()}
              >
                CRIAR
              </Button>
            ) : (
              <Button
                shape="round"
                type="primary"
                block
                size="large"
                onClick={() => handleSubmit(onUpdate)()}
              >
                Salvar
              </Button>
            )}
          </Col>
        </Row>
      </Spin>
    </Drawer>
  )
}
