import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import { Button, Col, Drawer, message, Row, Spin, Modal } from 'antd'
import { IconFont, Label, Title } from 'components'
import { useForm } from 'react-hook-form'
import { IFornecedor, status } from 'types'
import { MaskPhone } from 'services/utils'
import api from 'services/api'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import InputMasked from 'components/Controlled/InputMasked'
import Input from 'components/Controlled/Input'
import { useQueryClient } from 'react-query'

const { confirm } = Modal

interface drawer {
  drawer: status
  fornecedor?: IFornecedor
}

interface Props {
  config: {
    drawer: status
    fornecedor?: IFornecedor
  }
  setVisible: Dispatch<SetStateAction<drawer>>
}

export default function Fornecedor({
  config: { drawer, fornecedor },
  setVisible
}: Props): ReactElement {
  const { control, reset, handleSubmit, formState } = useForm({
    mode: 'onBlur'
  })

  const [waiting, setWaiting] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (drawer === 'update' && fornecedor) {
      reset({
        ...fornecedor,
        telefone: MaskPhone(fornecedor.telefone)
      })
    }
  }, [drawer])

  const onClose = () => {
    if (formState.touchedFields) {
      confirm({
        title: 'Você deseja realmente sair?',
        icon: <ExclamationCircleOutlined />,
        content: 'Essa ação é irreversível após a confirmação',
        okText: 'Confirmar',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: () => {
          reset({})
          setVisible({
            drawer: 'none'
          })
        }
      })
    } else {
      setVisible({
        drawer: 'none'
      })
    }
  }

  const onSave = async (data: any) => {
    setWaiting(true)
    try {
      await api.post('/fornecedores', data).then(() => {
        setWaiting(false)
        queryClient.invalidateQueries('fornecedores')
        reset({})
        setVisible({
          drawer: 'none'
        })
        message.success('Fornecedor cadastrado com sucesso!')
      })
    } catch (error) {
      setWaiting(false)
    }
  }

  const onUpdate = async (data: any) => {
    setWaiting(true)
    try {
      await api.put(`/fornecedores/${fornecedor?.id}`, data).then(() => {
        setWaiting(false)
        queryClient.invalidateQueries('fornecedores')
        reset({})
        onClose()
        message.success('Fornecedor atualizado com sucesso!')
      })
    } catch (error) {
      setWaiting(false)
    }
  }

  return (
    <Drawer
      destroyOnClose
      visible={drawer !== 'none'}
      onClose={onClose}
      width={400}
    >
      <Row justify="start" align="middle" style={{ margin: 10 }}>
        <IconFont
          type="icon-icon_user"
          style={{ fontSize: 24, marginRight: 15 }}
        />
        <Title>Novo Fornecedor</Title>
      </Row>
      <Spin spinning={waiting}>
        <Col span={24} style={{ margin: '10px 0' }}>
          <Label>NOME COMPLETO / NOME FANTASIA</Label>
          <Input
            name="nome"
            control={control}
            rules={{ required: 'O nome precisa ser informado' }}
            placeholder="Nome completo"
          />
        </Col>

        <Col span={24} style={{ margin: '10px 0' }}>
          <Label>TELEFONE</Label>
          <InputMasked.Telefone
            name="telefone"
            control={control}
            rules={{ required: 'O telefone precisa ser informado' }}
          />
        </Col>
        <Col span={24} style={{ margin: '10px 0' }}>
          <Label>EMAIL</Label>
          <Input.Email name="email" control={control} />
        </Col>
        <Row justify="space-between" gutter={8} style={{ margin: '10px 0' }}>
          <Col span={12}>
            <Button shape="round" size="large" block onClick={onClose}>
              CANCELAR
            </Button>
          </Col>
          <Col span={12}>
            {drawer === 'insert' ? (
              <Button
                shape="round"
                type="primary"
                block
                size="large"
                onClick={() => {
                  handleSubmit(onSave)()
                }}
              >
                CRIAR
              </Button>
            ) : (
              <Button
                shape="round"
                type="primary"
                block
                size="large"
                onClick={() => {
                  handleSubmit(onUpdate)()
                }}
              >
                Salvar
              </Button>
            )}
          </Col>
        </Row>
      </Spin>
    </Drawer>
  )
}
