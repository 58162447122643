import React, { useState } from 'react'
import {
  Button,
  Col,
  message,
  Row,
  Table,
  Typography,
  Space,
  Tooltip,
  Popconfirm
} from 'antd'
import { Card, Container, IconFont, InputSearch, Title } from 'components'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { IFornecedor } from 'types'
import api from 'services/api'
import { MaskPhone } from 'services/utils'
import Fornecedor from 'components/Drawer/Fornecedor'
import { useQueryClient } from 'react-query'
import { useFornecedores } from 'Hooks/functions'

type status = 'insert' | 'update' | 'none'
interface drawer {
  drawer: status
  fornecedor?: IFornecedor
}
const { Column } = Table

const Fornecedores: React.FC = () => {
  const [drawer, setDrawer] = useState<drawer>({
    drawer: 'none'
  })
  const queryClient = useQueryClient()
  const { data: fornecedores, isFetching: loading } = useFornecedores()

  const onDelete = async (id: number) => {
    await api.delete(`/fornecedor/${id}`).then(() => {
      queryClient.invalidateQueries('fornecedores')
      message.success('Cliente excluida com sucesso!')
    })
  }

  return (
    <Container>
      <Col span={24} style={{ padding: 10 }}>
        <Row justify="start" align="middle" style={{ margin: '10px 0' }}>
          <IconFont
            type="icon-icon_user"
            style={{ fontSize: 24, marginRight: 10 }}
          />
          <Title>Fornecedores</Title>
        </Row>
        <Row style={{ margin: '10px 0' }}>
          <Typography.Text type="secondary">
            {fornecedores ? fornecedores.length : 0} registros
          </Typography.Text>
        </Row>
        <Row wrap={false} gutter={8} style={{ margin: '10px 0' }}>
          <Col span={16}>
            <InputSearch size="large" placeholder="Pesquisar" />
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              shape="round"
              size="large"
              icon={<PlusOutlined />}
              onClick={() =>
                setDrawer({
                  drawer: 'insert'
                })
              }
            >
              NOVO
            </Button>
          </Col>
        </Row>
        <Card property="nopadding" style={{ margin: '10px 0' }}>
          <Table
            dataSource={fornecedores}
            loading={loading}
            rowKey={record => record.id}
          >
            <Column
              title="CÓD"
              dataIndex="id"
              key="id"
              responsive={['xxl', 'xl', 'lg']}
            />
            <Column title="NOME DO FORNECEDOR" dataIndex="nome" key="nome" />
            <Column<IFornecedor>
              title="TELEFONE"
              dataIndex="telefone"
              key="telefone"
              render={(_, { telefone }) =>
                telefone ? MaskPhone(telefone) : ''
              }
              responsive={['xxl', 'xl', 'lg', 'md']}
            />
            <Column
              title="EMAIL"
              dataIndex="email"
              key="email"
              responsive={['xxl', 'xl', 'lg', 'md']}
            />
            <Column<IFornecedor>
              key="actions"
              render={(_, record) => (
                <Space>
                  <Tooltip title="Editar">
                    <Button
                      icon={<EditOutlined />}
                      type="text"
                      shape="circle"
                      onClick={() => {
                        setDrawer({
                          drawer: 'update',
                          fornecedor: record
                        })
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Excluir">
                    <Popconfirm
                      title="Deseja excluir este fornecedor?"
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={() => onDelete(record.id)}
                    >
                      <Button
                        shape="circle"
                        type="text"
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </Tooltip>
                </Space>
              )}
            />
          </Table>
        </Card>
      </Col>
      <Fornecedor setVisible={setDrawer} config={drawer} />
    </Container>
  )
}

export default Fornecedores
