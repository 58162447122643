import React, { useEffect, useState } from 'react'
import {
  CheckSquareOutlined,
  FileDoneOutlined,
  PlusOutlined,
  SyncOutlined
} from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Collapse,
  message,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography
} from 'antd'
import {
  Container,
  Divisoria,
  IconFont,
  InputSearch,
  Label,
  Title
} from 'components'
import { formatarMoeda } from 'services/utils'
import { ICliente, IReceber, IReceberParcela } from 'types'
import api from 'services/api'
import { parseISO, format, isPast, isFuture } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import DrawerReceber from 'components/Drawer/Receber'

const { Column } = Table
const { Panel } = Collapse

type status = 'insert' | 'update' | 'none'
interface drawer {
  drawer: status
  conta?: IReceber
}

const Receber: React.FC = () => {
  const [clientesReceber, setClientesReceber] = useState<ICliente[]>(
    [] as ICliente[]
  )
  const [cliente, setCliente] = useState<ICliente | null>(null)
  const [loading, setLoading] = useState(false)
  const [drawer, setDrawer] = useState<drawer>({
    drawer: 'none'
  })

  useEffect(() => {
    async function getAReceber() {
      setLoading(true)
      try {
        await api.get('/receber').then(({ data }) => {
          setClientesReceber(data)
        })
      } finally {
        setLoading(false)
      }
    }
    getAReceber()
  }, [drawer])

  return (
    <Container>
      <Col span={8} style={{ padding: 10 }}>
        <Row justify="start" align="middle" style={{ marginBottom: 10 }}>
          <IconFont
            type="icon-Timealt2"
            style={{ fontSize: 24, marginRight: '1rem' }}
          />
          <Title>Contas a Receber</Title>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Typography.Text type="secondary">36 registros</Typography.Text>
        </Row>
        <Row wrap={false} justify="space-between" style={{ marginBottom: 10 }}>
          <InputSearch placeholder="Pesquisar" style={{ maxWidth: '65%' }} />
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={() =>
              setDrawer({
                drawer: 'insert'
              })
            }
          >
            NOVO
          </Button>
        </Row>
        <Row className="cardStyle">
          <Table
            loading={loading}
            dataSource={clientesReceber}
            rowKey={(cliente: ICliente) => cliente.id}
            onRow={record => {
              return {
                onClick: () => {
                  setCliente(record)
                }
              }
            }}
            scroll={{ y: 600 }}
            style={{ borderRadius: 20 }}
            pagination={{
              responsive: true,
              size: 'small',
              simple: true
            }}
          >
            <Column
              render={(_, cliente: ICliente) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Label>{cliente.nome}</Label>
                  <Typography.Text type="secondary">
                    Total a receber
                  </Typography.Text>
                </div>
              )}
            />
          </Table>
        </Row>
      </Col>
      <Col span={16} style={{ background: '#F9F9F9', padding: 10 }}>
        <Row justify="start" align="middle" style={{ marginBottom: 10 }}>
          <Title>Cliente: {cliente ? cliente.nome : '--'}</Title>
        </Row>
        <Row justify="start" align="middle" style={{ marginBottom: 10 }}>
          <Typography.Text type="secondary">{`${
            cliente?.aReceber.length || 0
          } contas a receber `}</Typography.Text>
        </Row>
        <Card bodyStyle={{ padding: 0 }}>
          <Row>
            <Col span={24}>
              <Collapse accordion>
                {cliente &&
                  cliente.aReceber.map(receber => {
                    const proximo = receber.parcelas.find(
                      parcela => !parcela.status
                    )
                    return (
                      <Panel
                        key={receber.id}
                        header={`${receber.valor} - ${format(
                          parseISO(receber.data),
                          'dd/MM/yyyy'
                        )}`}
                      >
                        <Row
                          style={{
                            background: 'rgba(253, 197, 0, 0.15)',
                            border: '1px solid #DDDDDD'
                          }}
                        >
                          <Col span={8}>
                            <Row align="middle" justify="center">
                              <SyncOutlined
                                style={{
                                  fontSize: 36,
                                  color: '#FDC500',
                                  marginRight: 5
                                }}
                              />

                              <Col>
                                <Title>
                                  {proximo
                                    ? format(
                                        parseISO(proximo.vencimento),
                                        "dd 'de' MMMM",
                                        { locale: ptBR }
                                      )
                                    : 'Pago'}
                                </Title>
                                <Typography.Text type="secondary">
                                  Próximo recebimento
                                </Typography.Text>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={8}>
                            <Row align="middle" justify="center">
                              <IconFont
                                type="icon-Timealt2"
                                style={{
                                  fontSize: 36,
                                  color: '#FDC500',
                                  marginRight: 5
                                }}
                              />
                              <Col>
                                <Title>
                                  {formatarMoeda(proximo ? proximo.valor : 0)}
                                </Title>
                                <Typography.Text type="secondary">
                                  Próximo a receber
                                </Typography.Text>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={8}>
                            <Row align="middle" justify="center">
                              <CheckSquareOutlined
                                style={{
                                  fontSize: 36,
                                  color: '#FDC500',
                                  marginRight: 5
                                }}
                              />
                              <Col>
                                <Title>{formatarMoeda(receber.valor)}</Title>
                                <Typography.Text type="secondary">
                                  Valor Total
                                </Typography.Text>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Divisoria />
                        <Table
                          dataSource={receber.parcelas}
                          rowKey={record => record.id}
                          loading={loading}
                        >
                          <Column<IReceberParcela>
                            title="STATUS"
                            key="status"
                            dataIndex="status"
                            render={(_, { status, pagamento, vencimento }) => {
                              if (status && pagamento) {
                                return <Tag color="green">pago</Tag>
                              }
                              if (!status && isPast(parseISO(vencimento))) {
                                return <Tag color="red">vencido</Tag>
                              }
                              if (!status && isFuture(parseISO(vencimento))) {
                                return <Tag>aguardando</Tag>
                              }
                            }}
                          />
                          <Column<IReceberParcela>
                            title="VALOR PARC."
                            key="valor"
                            dataIndex="valor"
                            render={valor => formatarMoeda(valor)}
                          />
                          <Column<IReceberParcela>
                            title="VENCIMENTO"
                            dataIndex="vencimento"
                            key="vencimento"
                            render={vencimento =>
                              format(parseISO(vencimento), 'dd/MM/yyyy')
                            }
                          />
                          <Column<IReceberParcela>
                            title="PARCELA"
                            dataIndex="parcela"
                            key="parcela"
                            responsive={['xl', 'lg']}
                          />
                          <Column<IReceberParcela>
                            title="RECEBIMENTO"
                            key="status"
                            dataIndex="status"
                            responsive={['xl', 'lg']}
                            render={status =>
                              status ? 'Recebido' : 'Não recebido'
                            }
                          />
                          <Column<IReceberParcela>
                            key="baixa"
                            render={(_, { id, status }) => {
                              const [baixa, setBaixa] = useState(status)

                              return baixa ? null : (
                                <Tooltip title="Dar Baixa">
                                  <Button
                                    icon={<FileDoneOutlined />}
                                    type="text"
                                    onClick={async () => {
                                      setLoading(true)
                                      await api
                                        .put(`/dar_baixa_receber/${id}`)
                                        .then(({ data }) => {
                                          message.success(
                                            'Atualizado com sucesso!'
                                          )
                                          setBaixa(true)
                                          setCliente(data)
                                        })
                                        .finally(() => {
                                          setLoading(false)
                                        })
                                    }}
                                  />
                                </Tooltip>
                              )
                            }}
                          />
                        </Table>
                      </Panel>
                    )
                  })}
              </Collapse>
            </Col>
          </Row>
        </Card>
      </Col>
      <DrawerReceber setVisible={setDrawer} config={drawer} />
    </Container>
  )
}

export default Receber
