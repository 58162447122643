import React, { useState } from 'react'
import { Button, Card, Col, Row, Spin, Table, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Container, IconFont, Title, InputSearch, Label } from 'components'
import Cadastro from 'components/Drawer/PDS/Cadastro'
import { IPedido, ICaixa } from 'types'
import Pedido from 'components/Drawer/PDS/Pedido'
import { useCaixaToday, usePedidos } from 'Hooks/functions'

const { Column } = Table

type status = 'insert' | 'update' | 'service' | 'payment' | 'none'

interface drawer {
  drawer: status
  pedido?: IPedido
  caixa: ICaixa | undefined
}

const Pedidos = () => {
  const [drawer, setDrawer] = useState<drawer>({
    drawer: 'none',
    caixa: undefined
  })
  const { data: pedidos, isFetching: loading } = usePedidos()
  const { data: caixa, isFetching } = useCaixaToday()

  return (
    <Container>
      <Col span={10} style={{ padding: 10 }}>
        <Spin spinning={isFetching}>
          <Row justify="center" align="middle" style={{ marginBottom: 10 }}>
            <IconFont type="icon-filetext" style={{ fontSize: 24 }} />
            <Title>Pedidos</Title>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Typography.Text type="secondary">
              {pedidos ? pedidos.length : 0} registros
            </Typography.Text>
          </Row>
          <Row
            wrap={false}
            justify="space-between"
            style={{ marginBottom: 10 }}
          >
            <InputSearch placeholder="Pesquisar" style={{ maxWidth: '65%' }} />
            <Button
              type="primary"
              shape="round"
              disabled={!caixa?.status}
              icon={<PlusOutlined />}
              onClick={() => setDrawer({ drawer: 'insert', caixa })}
            >
              NOVO
            </Button>
          </Row>
          <Card>
            <Table
              dataSource={pedidos}
              loading={loading || isFetching}
              rowKey={data => data.id}
              scroll={{ y: 600 }}
              style={{ borderRadius: 20 }}
              pagination={{
                responsive: true,
                size: 'small',
                simple: true
              }}
              onRow={record => {
                return {
                  onClick: () => {
                    setDrawer({ drawer: 'update', pedido: record, caixa })
                  }
                }
              }}
            >
              <Column
                render={(_, record: IPedido) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Label>{record.cliente.nome}</Label>
                    <Typography.Text type="secondary">{`Número do Pedido: ${record.id}`}</Typography.Text>
                  </div>
                )}
              />
            </Table>
          </Card>
        </Spin>
      </Col>
      <Pedido config={drawer} setVisible={setDrawer} />
      <Cadastro config={drawer} setVisible={setDrawer} />
    </Container>
  )
}

export default Pedidos
