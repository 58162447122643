import React from 'react'
import { Line } from '@ant-design/charts'
import { formatarMoeda } from 'services/utils'
import { useProgresso } from 'Hooks/functions'
import { Spin, Typography } from 'antd'

function Progresso() {
  const { data, isFetching } = useProgresso()

  return (
    <Spin spinning={isFetching}>
      {data && (
        <>
          <Typography.Title level={3}>Progresso</Typography.Title>
          <Line
            data={data}
            xField="mes"
            yField={'total'}
            seriesField="tipo"
            yAxis={{
              label: { formatter: formatarMoeda }
            }}
            legend={{
              position: 'top'
            }}
            tooltip={{
              formatter: val => {
                return { name: val.tipo, value: formatarMoeda(val.total) }
              }
            }}
            color={['#00a2ae', '#ffa500']}
            smooth
          />
        </>
      )}
    </Spin>
  )
}

export default Progresso
