import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import { Drawer, Row, Spin, Col, Button, message } from 'antd'
import { IconFont, Title, Label, InputText, InputMoney } from 'components'
import { useForm } from 'react-hook-form'
import api from 'services/api'
import { ICaixa, IPedido, IProduto } from 'types'
import Input from 'components/Controlled/Input'
import InputMasked from 'components/Controlled/InputMasked'
import Select from 'components/Controlled/Select'
import { useQueryClient } from 'react-query'
import { useProdutos } from 'Hooks/functions'
import Produto from '../Produto'

type status = 'insert' | 'update' | 'service' | 'payment' | 'none'

interface drawer {
  drawer: status
  pedido?: IPedido
  caixa: ICaixa | undefined
}

interface drawerAdicional {
  drawer: 'insert' | 'update' | 'none'
  produto?: IProduto
}

interface Props {
  config: drawer
  setVisible: Dispatch<SetStateAction<drawer>>
}

export default function AddProduto({
  config: { drawer, caixa, pedido },
  setVisible
}: Props): ReactElement {
  const queryClient = useQueryClient()
  const { control, reset, handleSubmit, watch } = useForm({ mode: 'onBlur' })
  const [waiting, setWaiting] = useState(false)
  const { data: produtos, isFetching } = useProdutos()
  const produtoId = watch('produtoId')
  const [selected, setSelected] = useState<{ unidade: string; valor: number }>({
    unidade: '',
    valor: 0
  })
  const [drawerProduto, setDrawerProduto] = useState<drawerAdicional>({
    drawer: 'none'
  })

  useEffect(() => {
    if (produtoId) {
      const temp = produtos?.find(serv => serv.id === produtoId)
      if (temp) {
        setSelected({
          unidade: temp.unidade.unidade,
          valor: Number(temp.valor)
        })
      }
    }
  }, [produtoId])

  const onClose = () => {
    reset({ desconto: 0 })
    setSelected({ unidade: '', valor: 0 })
    setVisible({ drawer: 'update', caixa, pedido })
  }

  const addService = async (data: any) => {
    try {
      if (pedido) {
        await api.put(`/add_produto/${pedido.id}`, data).then(({ data }) => {
          reset({ desconto: 0 })
          setSelected({ unidade: '', valor: 0 })
          setVisible({
            drawer: 'update',
            caixa,
            pedido: { ...pedido, produtos: data }
          })
          queryClient.invalidateQueries('pedidos')
          message.success('Produto adicionado com sucesso!')
        })
      }
    } finally {
      setWaiting(false)
    }
  }

  return (
    <Drawer visible={drawer === 'service'} onClose={onClose} width={400}>
      <Row justify="start" align="middle" style={{ margin: 10 }}>
        <IconFont
          type="icon-filetext"
          style={{ fontSize: 24, marginRight: 15 }}
        />
        <Title>Adicionar Produto</Title>
      </Row>
      <Spin spinning={waiting || isFetching}>
        <Col span={24} style={{ margin: '10px 0' }}>
          <Label>Produto</Label>
          <Select
            control={control}
            name="produtoId"
            placeholder="Selecione..."
            rules={{ required: 'O produto precisa ser informado' }}
          >
            {produtos?.map(({ id, nome }) => (
              <Select.Option key={id} value={id}>
                {nome}
              </Select.Option>
            ))}
          </Select>
          <Button
            block
            type="text"
            onClick={() =>
              setDrawerProduto({
                drawer: 'insert'
              })
            }
          >
            Adicionar Produto
          </Button>
        </Col>
        <Row wrap={false} gutter={8} style={{ margin: '10px 0' }}>
          <Col span={12}>
            <Label>UNIDADE</Label>
            <InputText size="large" value={selected.unidade || ''} disabled />
          </Col>
          <Col span={12}>
            <Label>QUANTIDADE</Label>
            <Input
              name="quantidade"
              control={control}
              rules={{ required: 'A quantidade precisa ser informada' }}
              type="number"
            />
          </Col>
        </Row>
        <Row wrap={false} gutter={8} style={{ margin: '10px 0' }}>
          <Col span={12}>
            <Label>VALOR DO PRODUTO</Label>
            <InputMoney value={selected.valor || 0} disabled />
          </Col>
          <Col span={12}>
            <Label>DESCONTO</Label>
            <InputMasked.Money
              name="desconto"
              control={control}
              defaultValue={0}
            />
          </Col>
        </Row>
        <Row justify="space-between" gutter={8} style={{ margin: '10px 0' }}>
          <Col span={12}>
            <Button shape="round" size="large" block onClick={onClose}>
              CANCELAR
            </Button>
          </Col>
          <Col span={12}>
            <Button
              shape="round"
              type="primary"
              block
              size="large"
              onClick={() => {
                handleSubmit(addService)()
              }}
            >
              ADICIONAR
            </Button>
          </Col>
        </Row>
      </Spin>
      <Produto config={drawerProduto} setVisible={setDrawerProduto} />
    </Drawer>
  )
}
