import React, { useEffect, useState } from 'react'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  ToolOutlined
} from '@ant-design/icons'
import {
  Col,
  Row,
  Typography,
  Button,
  Table,
  Drawer,
  message,
  Space,
  Tooltip,
  Popconfirm
} from 'antd'
import { Card, Container, InputText, Label, Title } from 'components'
import { useForm, SubmitHandler } from 'react-hook-form'
import InputMasked from 'components/Controlled/InputMasked'
import { useQueryClient } from 'react-query'
import Select from 'components/Controlled/Select'
import Input from 'components/Controlled/Input'
import { IUsuario } from 'types'
import api from 'services/api'
import { useCargos, useUsuarios } from 'Hooks/functions'

const { Column } = Table
type status = 'insert' | 'update' | 'none'

const Usuarios: React.FC = () => {
  const [drawer, setDrawer] = useState<status>('none')
  const [usuario, setUsuario] = useState(0)
  const [filter, setFilter] = useState<IUsuario[]>([] as IUsuario[])
  const { handleSubmit, control, reset } = useForm({ mode: 'onBlur' })
  const queryClient = useQueryClient()
  const { data: usuarios, isFetching: loading } = useUsuarios()
  const { data: cargos, isFetching } = useCargos()

  const onDelete = async (id: number) => {
    await api.delete(`/usuario/${id}`).then(() => {
      queryClient.invalidateQueries('usuarios')
      message.success('Usuário excluido com sucesso!')
    })
  }

  useEffect(() => {
    if (usuarios) {
      setFilter(usuarios)
    }
  }, [usuarios])

  const onSubmit: SubmitHandler<any> = async data => {
    drawer === 'update'
      ? await api.put(`/usuario/${usuario}`, data).then(() => {
          queryClient.invalidateQueries('usuarios')
          message.success('Usuário atualizado com sucesso!')
          reset({})
          setDrawer('none')
        })
      : await api.post('/usuario', data).then(() => {
          queryClient.invalidateQueries('usuarios')
          message.success('Usuário cadastrado com sucesso!')
          reset({})
          setDrawer('none')
        })
  }

  const onClose = () => {
    reset({})
    setDrawer('none')
  }

  return (
    <Container>
      <Col span={24} style={{ padding: 10 }}>
        <Row justify="start" align="middle" style={{ margin: '10px 0' }}>
          <ToolOutlined style={{ fontSize: 24, marginRight: 10 }} />
          <Title>Usuários</Title>
        </Row>
        <Row style={{ margin: '10px 0' }}>
          <Typography.Text type="secondary">
            {usuarios ? usuarios.length : 0} registros
          </Typography.Text>
        </Row>
        <Row wrap={false} gutter={8} style={{ margin: '10px 0' }}>
          <Col span={16}>
            <InputText
              size="large"
              placeholder="Pesquisar"
              onChange={e => {
                if (usuarios) {
                  setFilter(
                    usuarios.filter(usuario =>
                      usuario.nome
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  )
                }
              }}
              suffix={<SearchOutlined />}
            />
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              shape="round"
              size="large"
              icon={<PlusOutlined />}
              onClick={() => setDrawer('insert')}
            >
              NOVO
            </Button>
          </Col>
        </Row>
        <Card property="nopadding" style={{ margin: '10px 0' }}>
          <Table
            dataSource={filter}
            loading={loading || isFetching}
            rowKey={record => record.id}
          >
            <Column title="NOME" dataIndex="nome" key="nome" />
            <Column
              title="EMAIL"
              dataIndex="email"
              key="email"
              responsive={['xxl', 'xl', 'lg', 'md']}
            />
            <Column
              title="CARGO"
              dataIndex="cargo"
              key="cargo"
              render={(_, record: IUsuario) => record.cargo.nome}
            />
            <Column
              key="actions"
              render={(_, record: IUsuario) => (
                <Space>
                  <Tooltip title="Editar" placement="top">
                    <Button
                      shape="circle"
                      type="text"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setDrawer('update')
                        setUsuario(record.id)
                        reset(record)
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Apagar" placement="top">
                    <Popconfirm
                      title="Deseja realmente apagar este registro?"
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={async () => onDelete(record.id)}
                    >
                      <Button
                        shape="circle"
                        type="text"
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </Tooltip>
                </Space>
              )}
            />
          </Table>
        </Card>
      </Col>

      <Drawer
        visible={drawer !== 'none'}
        onClose={onClose}
        destroyOnClose={true}
        width={400}
        title={
          <Row justify="start" align="middle" style={{ margin: 10 }}>
            <ToolOutlined style={{ fontSize: 24, marginRight: 15 }} />
            <Title>{drawer === 'insert' ? 'Nova' : 'Editar'} Unidade</Title>
          </Row>
        }
      >
        <Col span={24} style={{ margin: '10px 0' }}>
          <Label>NOME DO USUÁRIO</Label>
          <Input
            name="nome"
            control={control}
            placeholder="Nome"
            rules={{
              required: 'O nome do usuário precisa ser informado'
            }}
          />
          <Label>EMAIL</Label>
          <Input.Email
            name="email"
            control={control}
            rules={{
              required: 'O email precisa ser informado'
            }}
          />
          <Label>CPF</Label>
          <InputMasked.CPFCNPJ
            name="cpf"
            control={control}
            rules={{ required: 'O CPF precisa ser informado' }}
          />
          <Label>SENHA</Label>
          <Input.Password
            name="password"
            placeholder="Senha"
            control={control}
            rules={{
              required: 'A senha precisa ser informada'
            }}
          />
          <Label>CARGO</Label>
          <Select
            name="cargoId"
            control={control}
            rules={{
              required: 'O cargo precisa ser informado'
            }}
            placeholder="Selecione..."
            showSearch
            optionFilterProp="children"
          >
            {cargos?.map(cargo => (
              <Select.Option key={cargo.id} value={cargo.id}>
                {cargo.nome}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Row justify="space-between" gutter={8} style={{ margin: '10px 0' }}>
          <Col span={12}>
            <Button shape="round" size="large" block onClick={onClose}>
              CANCELAR
            </Button>
          </Col>
          <Col span={12}>
            <Button
              shape="round"
              type="primary"
              block
              size="large"
              onClick={() => handleSubmit(onSubmit)()}
            >
              {drawer === 'insert' ? 'CRIAR' : 'SALVAR'}
            </Button>
          </Col>
        </Row>
      </Drawer>
    </Container>
  )
}

export default Usuarios
