import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  #root {
    height: 100%;
  }
  .ant-select-selector{
  border-radius: 10px !important;
  background-color: #eff0f6 !important ;
  }
  .ant-input[disabled]{
    color: #000;
  }
  .ant-collapse-content > .ant-collapse-content-box{
    padding: 0;
  }
  .ant-collapse-content-box{
    padding: 0;
  }
  .cardStyle {
    border-radius: 10px;
    border: 1px solid #DDDDDD;
    overflow: hidden;
  }

  .ant-picker {
    border-radius: 20px;
  }

  .rightBorder{
    border-right: 1px solid #DDDDDD;
  }
  .leftBorder {
    border-left: 1px solid #DDDDDD;
  }
  .topBorder {
    border-top: 1px solid #DDDDDD;
  }
  .bottomBorder{
    border-bottom: 1px solid #DDDDDD;
  }
  .marginBottom{
    margin-bottom: 1rem;
  }
  .marginTop{
    margin-top: 1rem;
  }

  .mc-input{
    border-radius: 10px;
    background-color: #eff0f6;
    input {
      background-color: #eff0f6;
    }
  }

  .container {
        height: 100vh;
        width: 100%;
        background-image: url('./img/bg.jpg') ;
        background-repeat: no-repeat;
        background-size: cover;
    }

  .loginForm{
      background-color: rgba(255, 255, 255, 0.9);
      padding: 1rem;
      border-radius: 15px;
  }

  .marginTopBottom{
      margin: 1rem 0;
  }

  .logo {
    max-height: 5rem;
    margin-top: 1rem;
    svg {
      max-height: 5rem;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

`
