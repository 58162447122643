import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import {
  Drawer,
  Row,
  Spin,
  Col,
  Button,
  Table,
  Typography,
  message,
  Modal
} from 'antd'
import { IconFont, Title, Label, Card } from 'components'
import DatePicker from 'components/DatePicker'
import { format } from 'date-fns'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import api from 'services/api'
import { formatarMoeda } from 'services/utils'
import { IReceber, status } from 'types'
import Select from 'components/Controlled/Select'
import InputMasked from 'components/Controlled/InputMasked'
import { useClientes } from 'Hooks/functions'

const { confirm } = Modal
const { Column } = Table

interface drawer {
  drawer: status
  conta?: IReceber
}

interface Props {
  config: drawer
  setVisible: Dispatch<SetStateAction<drawer>>
}

export default function Receber({
  config: { drawer, conta },
  setVisible
}: Props): ReactElement {
  const [waiting, setWaiting] = useState(false)
  const { data: clientes } = useClientes()
  const {
    control,
    trigger,
    getValues,
    setValue,
    clearErrors,
    reset,
    formState
  } = useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parcelas'
  })

  useEffect(() => {
    if (drawer === 'update' && conta) {
      reset(conta)
    }
  }, [drawer])

  const onClose = () => {
    if (formState.touchedFields) {
      confirm({
        title: 'Você deseja realmente sair?',
        icon: <ExclamationCircleOutlined />,
        content: 'Essa ação é irreversível após a confirmação',
        okText: 'Confirmar',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: () => {
          reset({})
          setVisible({
            drawer: 'none'
          })
        }
      })
    } else {
      setVisible({
        drawer: 'none'
      })
    }
  }

  const onSave = async () => {
    const data = getValues()
    let total = 0
    data.parcelas.forEach(
      (parcela: { valor: number }) => (total += parcela.valor)
    )
    setWaiting(true)
    await api
      .post('/receber', { ...data, valor: total })
      .then(() => {
        message.success('Lançamento feito com sucesso!')
        reset({ parcelas: [] })
        setVisible({
          drawer: 'none'
        })
      })
      .finally(() => {
        setWaiting(false)
      })
  }

  return (
    <Drawer
      width={450}
      visible={drawer !== 'none'}
      destroyOnClose
      onClose={onClose}
    >
      <Row justify="start" align="middle" style={{ margin: 10 }}>
        <IconFont
          type="icon-Timealt2"
          style={{ fontSize: 24, marginRight: 15 }}
        />
        <Title>Nova Conta a Receber</Title>
      </Row>
      <Spin spinning={waiting}>
        <Col span={24} style={{ margin: '10px 0' }}>
          <Label>CLIENTE</Label>
          <Select
            placeholder="Selecione..."
            control={control}
            rules={{ required: 'O cliente precisa ser informado' }}
            name="clienteId"
          >
            {clientes?.map(({ nome, id }) => (
              <Select.Option value={id} key={id}>
                {nome}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Row wrap={false} gutter={8} style={{ margin: '10px 0' }}>
          <Col span={12}>
            <Label>VALOR</Label>
            <InputMasked.Money
              name="valor"
              control={control}
              rules={{
                required: 'O valor precisa ser informado',
                min: { value: 1, message: 'O valor precisa ser informado' }
              }}
              allowNegative={false}
            />
          </Col>
          <Col span={12}>
            <Label>DATA</Label>
            <Controller
              name="vencimento"
              control={control}
              rules={{ required: 'A data precisa ser informada' }}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  style={{ width: '100%' }}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Col>
        </Row>
        <Row wrap={false} gutter={8} style={{ margin: '10px 0' }}>
          <Col span={24}>
            <Button
              icon={<PlusOutlined />}
              shape="round"
              type="primary"
              block
              size="large"
              onClick={async () => {
                const validated = await trigger(['valor', 'vencimento'])
                if (validated) {
                  append({
                    vencimento: getValues('vencimento'),
                    valor: getValues('valor')
                  })
                  setValue('vencimento', undefined)
                  setValue('valor', 0)
                  clearErrors()
                }
                clearErrors()
              }}
            >
              ADICIONAR
            </Button>
          </Col>
        </Row>
        <Card property="nopadding">
          <Table
            dataSource={fields}
            rowKey={record => record.id}
            pagination={{ position: [] }}
            summary={data => {
              const t = data as unknown
              const temp = t as any
              let total = 0
              temp.forEach((parcela: { valor: number }) => {
                total += parcela.valor
              })
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Label>TOTAL</Label>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Label>{formatarMoeda(total)}</Label>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} />
                  <Table.Summary.Cell index={3} />
                </Table.Summary.Row>
              )
            }}
          >
            <Column
              title="PARCELA"
              key="parcela"
              render={(_, __, index) => (
                <Controller
                  control={control}
                  name={`parcelas[${index}].parcela`}
                  defaultValue={index + 1}
                  render={({ field: { value } }) => (
                    <Typography.Text>
                      {`${value}/${fields.length}`}
                    </Typography.Text>
                  )}
                />
              )}
            />
            <Column
              title="VALOR"
              key="valor"
              render={(_, record: { valor: number }, index) => (
                <Controller
                  control={control}
                  name={`parcelas[${index}].valor`}
                  defaultValue={record.valor}
                  render={({ field: { value } }) => (
                    <Typography.Text>{formatarMoeda(value)}</Typography.Text>
                  )}
                />
              )}
            />
            <Column
              title="DATA"
              key="vencimento"
              render={(_, record: { vencimento: Date }, index) => (
                <Controller
                  control={control}
                  name={`parcelas[${index}].vencimento`}
                  defaultValue={record.vencimento}
                  render={({ field: { value } }) => (
                    <Typography.Text>
                      {value ? format(value, 'dd/MM/yyyy') : '-'}
                    </Typography.Text>
                  )}
                />
              )}
            />
            <Column
              key="actions"
              render={(_, __, index) => (
                <Button
                  shape="circle"
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    remove(index)
                  }}
                />
              )}
            />
          </Table>
        </Card>
        <Row justify="space-between" gutter={8} style={{ margin: '10px 0' }}>
          <Col span={12}>
            <Button shape="round" size="large" block onClick={onClose}>
              CANCELAR
            </Button>
          </Col>
          <Col span={12}>
            <Button
              shape="round"
              type="primary"
              block
              size="large"
              onClick={onSave}
            >
              CRIAR
            </Button>
          </Col>
        </Row>
      </Spin>
    </Drawer>
  )
}
